<template>
  <div class="home">
    <div class="baner">
      <!--基础存放容器-->
      <div class="swiper-container my_swiper-container1">
        <!-- 需要进行轮播的部分 -->
        <div class="swiper-wrapper">
          <!-- 每个节点 -->
          <div class="swiper-slide" v-for="(item, index) in list" :key="index">
            <img :src="item.imageUrl" :alt="item.title" />
          </div>
        </div>
        <!--如果需要使用分页器-->
        <!-- <div class="swiper-pagination swiper-pagination-white"></div> -->
        <!-- 如果需要使用前进后退按钮 -->
        <div class="swiper-button-prev swiper-button-white"></div>
        <div class="swiper-button-next swiper-button-white"></div>
      </div>
    </div>
    <!-- 数据展示 -->
    <div class="w dataShow dis-f flex-jcsb">
      <div><img src="@/assets/images/kapian1.png" alt="" srcset="" /></div>
      <div><img src="@/assets/images/kapian2.png" alt="" srcset="" /></div>
      <div><img src="@/assets/images/kapian3.png" alt="" srcset="" /></div>
    </div>

    <!-- 推荐课程 -->
    <div class="w recommendCourse">
      <div class="index_title dis-f flex-jcsb">
        <h4>推荐课程</h4>
        <!-- <div class="more pointer">更多</div> -->
      </div>
      <div class="list dis-f">
        <div
          class="item"
          v-for="(item, index) of recommendCourseLists"
          :key="index"
          @click="goDetails(item)"
        >
          <img :src="item.imageUrl" alt="" />
          <div class="info">
            <p class="course_content inp_hidden2">{{ item.courseName }}</p>
            <p class="num">共计{{ item.courseTime }}课时</p>
            <p class="tag">
              <span>{{ item.industryName }}</span
              ><span>{{ item.typeWorkName }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 平台专家 -->
    <div class="w platformExpert" v-if="false">
      <div class="index_title dis-f flex-jcsb">
        <h4>平台专家</h4>
        <!-- <div class="more pointer">更多</div> -->
      </div>
      <div class="list dis-f">
        <div class="item" v-for="(item, index) of [1, 1, 1, 1, 1]" :key="index">
          <img src="@/assets/images/platformExpert.png" alt="" />
          <div class="info">
            <p class="name">张三</p>
            <p class="position">一级企业人力资源管理师</p>
          </div>
          <div class="details">
            <p>内容暂定</p>
            <p>样式也先不写</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 系列课程 -->
    <div class="w seriesCourse">
      <div class="index_title dis-f flex-jcsb">
        <h4>系列课程</h4>
        <!-- <div class="more pointer">更多</div> -->
      </div>
      <!--基础存放容器-->
      <div class="swiper-container my_swiper-container2">
        <!-- 需要进行轮播的部分 -->
        <div class="swiper-wrapper">
          <!-- 每个节点 -->
          <div
            class="swiper-slide"
            v-for="(item, index) of [1, 1, 1, 1, 1, 1, 1]"
            :key="index"
          >
            <img src="@/assets/images/dianzhishangwu.png" alt="图片1" />
          </div>
        </div>
        <!-- 如果需要使用前进后退按钮 -->
        <div class="swiper-button-prev swiper-button-white"></div>
        <div class="swiper-button-next swiper-button-white"></div>
      </div>
    </div>
    <!-- 精品课程 -->
    <div class="w recommendCourse boutiqueCourse">
      <div class="index_title dis-f flex-jcsb">
        <h4>精品课程</h4>
        <!-- <div class="more pointer">更多</div> -->
      </div>
      <div class="list dis-f flex-wrap">
        <div
          class="item"
          v-for="(item, index) of boutiqueCourseLists"
          :key="index"
          style="margin-bottom: 40px"
          @click="goDetails(item)"
        >
          <img :src="item.imageUrl" alt="" />
          <div class="info">
            <p class="course_content inp_hidden2">{{ item.courseName }}</p>
            <p class="num">共计{{ item.courseTime }}课时</p>
            <p class="tag">
              <span>{{ item.industryName }}</span
              ><span>{{ item.typeWorkName }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 免费课程 -->
    <div class="w recommendCourse freeCourse">
      <div class="index_title dis-f flex-jcsb">
        <h4>免费课程</h4>
        <!-- <div class="more pointer">更多</div> -->
      </div>
      <div class="list dis-f flex-wrap">
        <div
          class="item"
          v-for="(item, index) of freeCourseLists"
          :key="index"
          style="margin-bottom: 40px"
          @click="goDetails(item)"
        >
          <img :src="item.imageUrl" alt="" />
          <div class="info">
            <p class="course_content inp_hidden2">{{ item.courseName }}</p>
            <p class="num">共计{{ item.courseTime }}课时</p>
            <p class="tag">
              <span>{{ item.industryName }}</span
              ><span>{{ item.typeWorkName }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/api/api";
export default {
  name: "home",
  data() {
    return {
      list: [],
      recommendCourseLists: [],
      // seriesCourseLists:[],
      boutiqueCourseLists: [],
      freeCourseLists: [],
    };
  },
  async mounted() {
    await this.getBannerLists();
    await this.getrecommendCourseLists();
    // await this.getseriesCourseLists();
    await this.getboutiqueCourseLists();
    await this.getfreeCourseLists();
    await this.initSwiper();
  },
  methods: {
    initSwiper() {
      new Swiper(".my_swiper-container1", {
        //设置自动播放间隔时间
        autoplay: 3000,
        //设置轮播的循环方式
        loop: false,
        // 轮播效果,默认为平滑轮播
        effect: "slide",
        slidesPerView: 1,
        //分页器
        // pagination: ".swiper-pagination",
        //前进后退按钮
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
        // 用户中断轮播后续播
        autoplayDisableOnInteraction: false,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper 
        observeParents:true//修改swiper的父元素时，自动初始化swiper 
      });
      new Swiper(".my_swiper-container2", {
        loop: true,
        effect: "slide",
        slidesPerView: 5,
        spaceBetween: 6,
        //前进后退按钮
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
      });
    },
    getBannerLists() {
      API.bannerLists()
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data;
            
          }
        })
        .catch(() => {
          this.$message.error("请求异常");
        });
    },
    // 推荐课程
    getrecommendCourseLists() {
      API.homepageCourse({
        ShowModule: 1,
        Status: 0,
        pageSize: 4,
        pageIndex: 1,
      })
        .then((res) => {
          if (res.code == 200) {
            this.recommendCourseLists = res.data.rows;
          }
        })
        .catch(() => {
          this.$message.error("请求异常");
        });
    },
    // 系列课程
    // getseriesCourseLists() {
    //     API.homepageCourse({
    //         ShowModule:1,Status:0,pageSize:4,pageIndex:1
    //     }).then(res => {
    //         if(res.code == 200) {
    //             this.seriesCourseLists = res
    //         }
    //     }).catch(() => {
    //         this.$message.error('请求异常')
    //     })
    // },
    // 精品课程
    getboutiqueCourseLists() {
      API.homepageCourse({
        ShowModule: 2,
        Status: 0,
        pageSize: 8,
        pageIndex: 1,
      })
        .then((res) => {
          if (res.code == 200) {
            this.boutiqueCourseLists = res.data.rows;
          }
        })
        .catch(() => {
          this.$message.error("请求异常");
        });
    },
    // 免费课程
    getfreeCourseLists() {
      API.homepageCourse({
        ShowModule: 3,
        Status: 0,
        pageSize: 8,
        pageIndex: 1,
      })
        .then((res) => {
          if (res.code == 200) {
            this.freeCourseLists = res.data.rows;
          }
        })
        .catch(() => {
          this.$message.error("请求异常");
        });
    },
    // 详情
    goDetails(item) {
      this.$router.push({
        path: "/courseDateils",
        query: { id: item.courseId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  .baner {
    width: 100vw;
    .swiper-container {
      width: 100%;
      height: 330px;
    }
    .swiper-slide img {
      width: 100%;
      object-fit: cover;
    }
  }
  .dataShow {
    padding: 86px 0px;
    div {
      transition: transform 0.5s;
      &:hover {
        transform: translate(0, -8px);
        // box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.2);
        transition: transform 0.5s;
        
      }
    }
  }
  .recommendCourse {
    padding-bottom: 86px;
    .list {
      width: 100%;
      .item {
        width: 283px;
        height: 290px;
        background: #ffffff;
        border-radius: 10px;
        margin-right: 22px;
        transition: transform 0.5s;
        &:hover {
          transform: translate(0, -8px);
          transition: transform 0.5s;
          box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.08);
        }
        &:nth-of-type(4n) {
          margin: 0px;
        }
        &:last-of-type {
          margin-right: 0px;
        }
        img {
          width: 283px;
          height: 159px;
          display: block;
        }
        .info {
          padding: 15px 21px 27px 21px;
          .course_content {
            height: 44px;
            font-size: 18px;
            color: #333333;
            line-height: 22px;
          }
          .num {
            font-size: 14px;
            color: #666666;
            line-height: 32px;
          }
          .tag {
            span {
              display: inline-block;
              width: 68px;
              height: 24px;
              background: #f2f5f7;
              border-radius: 3px;
              font-size: 13px;
              color: #5b6068;
              line-height: 24px;
              text-align: center;
              margin-right: 17px;
            }
          }
        }
      }
    }
  }
  .platformExpert {
    margin-bottom: 86px;
    .list {
      width: 100%;
      height: 380px;
      .item {
        width: 240px;
        position: relative;
        img {
          display: block;
          width: 100%;
          height: 280px;
        }
        .info {
          width: 100%;
          height: 100px;
          padding: 0px 15px;
          .name {
            font-size: 18px;
            color: #333333;
            line-height: 1;
            padding: 19px 0px 26px 0px;
            position: relative;
            &::after {
              content: "";
              width: 24px;
              height: 4px;
              background: #0095ff;
              border-radius: 2px;
              position: absolute;
              bottom: 16px;
              left: 0px;
            }
          }
          .position {
            font-size: 14px;
            color: #a0a3ac;
            line-height: 1;
          }
        }
        .details {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 240px;
          height: 0px;
          opacity: 0;
          background: #1988e6;
          border-radius: 0px 20px 10px 10px;
          transition: all 0.8s;
        }
        &:hover {
          .details {
            height: 188px;
            opacity: 0.95;
            transition: all 0.8s;
          }
        }
      }
    }
  }
  .seriesCourse {
    overflow: hidden;
    position: relative;
    .my_swiper-container2 {
      width: 100%;
      height: 330px;
    }
    .swiper-button-prev.swiper-button-white,
    .swiper-container-rtl .swiper-button-next.swiper-button-white {
      background: url("@/assets/images/jiantuo_zou.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-next.swiper-button-white,
    .swiper-container-rtl .swiper-button-prev.swiper-button-white {
      background: url("@/assets/images/jiantuo_you.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 40px !important;
      height: 40px !important;
      border-radius: 20px !important;
    }
    .swiper-slide img {
      width: 100%;
      object-fit: cover;
    }
  }
  .index_title {
    margin-bottom: 28px;
    h4 {
      font-size: 24px;
      color: #333333;
      letter-spacing: 0.6px;
      position: relative;
      padding-left: 19px;
      &::after {
        content: "";
        width: 4px;
        height: 24px;
        background: #0095ff;
        border-radius: 2px;
        position: absolute;
        top: 4px;
        left: 0px;
      }
    }
    .more {
      font-size: 16px;
      color: #999999;
      line-height: 32px;
      letter-spacing: 0.4px;
      position: relative;
      padding-right: 24px;
      &::after {
        content: "";
        width: 15px;
        height: 15px;
        background: url("@/assets/images/gengduo.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 8px;
        right: 0px;
      }
    }
  }
}
</style>
