<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-16 16:45:29
 * @LastEditTime: 2022-10-25 10:13:51
 * @FilePath: \website\src\views\platform\index.vue
-->
<template>
    <div class="platform">
        <div class="banner"></div>
        <div class="box1">
            <div class="w dis-f flex-jcsb flex-aic">
                <div class="img"></div>
                <div class="text flex1">
                    <h3>慧职技平台</h3>
                    <p>致力于帮助企业/机构构建线上培训体系，实现数字培训 ，整合多元化多层次培训教材，塑造可持续性培训; </p>
                    <p>共同探索互联网+职业培训，从而实现多方需求，构建完善的全业态健康发展。</p>
                </div>
            </div>
        </div>
        <div class="box2">
            <h1 class="text-c">深入场景化 释放价值</h1>
            <div class="w dis-f flex-jcsb flex-aic">
                <div v-if="box2Current==1" class="img">
                    <img src="@/assets/images/platform/xianshangxianxia@2x.png" alt="">
                </div>
                <div v-if="box2Current==2" class="img">
                    <img src="@/assets/images/platform/gaoxiaoxueyuan@2x.png" alt="">
                </div>
                <div v-if="box2Current==3" class="img">
                    <img src="@/assets/images/platform/zengzhangshuju@2x.png" alt="">
                </div>
                <div class="text flex1">
                    <div class="tab" @click="box2TabClick(item)" v-for="item in box2TabList" :key="item.id">
                        <img v-if="box2Current != item.id" :src="item.img" alt="">
                        <img v-if="box2Current == item.id" :src="item.img_active" alt="">
                        <span>{{item.tit}}</span>
                    </div>
                    <!-- <div class="tab">
                        <img src="@/assets/images/platform/xianshang.png" alt="">
                        <span>高效学员管理</span>
                    </div>
                    <div class="tab">
                        <img src="@/assets/images/platform/xianshang.png" alt="">
                        <span>增长数据驱动</span>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="box3">
            <h1 class="text-c">平台特性</h1>
            <div class="w">
                <div class="list dis-f flex-jcsb flex-wrap">
                    <div class="item text-c" v-for="(item,index) in characteristicLists" :key="index">
                        <img :src="item.url" alt="">
                        <h3>{{item.h3Text}}</h3>
                        <p>{{item.pText}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="box4">
            <h1 class="text-c">学员评价</h1>
            <div class="w">
                <div class="my_swiper">
                    <ul class="ul dis-f flex-jcsb pos-r flex-aic" :style="`transform:translateX(${swiperPosition}px)`">
                        <li :class="swiperIndex==index?'active':''" v-for="(item,index) in evaluateList" :key="index">
                            <!-- <img src="@/assets/images/tab_img.png" alt=""> -->
                            <p>{{item.con}}</p>
                            <div class="dis-f pos-a flex-aic student ">
                                <img :src="item.imgUrl" alt="">
                                <span class="name">{{item.name}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="btn dis-f flex-jce">
                    <div @click="prev"><i class="el-icon-arrow-left"></i></div>
                    <div @click="next"><i class="el-icon-arrow-right"></i></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"platform",
    data() {
        return {
            characteristicLists:[
                {
                    url:require('@/assets/images/characteristic1.png'),
                    h3Text:"低投入",
                    pText:"按需付费，保证资源成本适配"
                },{
                    url:require('@/assets/images/characteristic2.png'),
                    h3Text:"上线快",
                    pText:"SAAS模式免部署，易实施，线上反馈解决"
                },{
                    url:require('@/assets/images/characteristic3.png'),
                    h3Text:"免维护",
                    pText:"平台统一维护，保持最新版本，免费升级"
                },{
                    url:require('@/assets/images/characteristic4.png'),
                    h3Text:"易管理",
                    pText:"整合产品工具，学员班级一体化管理"
                },{
                    url:require('@/assets/images/characteristic5.png'),
                    h3Text:"高效率",
                    pText:"业务场景全覆盖，流程化管理"
                },{
                    url:require('@/assets/images/characteristic6.png'),
                    h3Text:"高回报",
                    pText:"深度融合，提供更丰富的增值服务"
                }
            ],
            evaluateList: [{
                con:'慧职技为提供了完整的职业培训解决方案，帮助我们将线下业务与线上业务融合，打通了内容到教学再到考评的完整闭环，全方位提高了我们的拓展能力。',
                name:"刘某",
                imgUrl:require('@/assets/images/platform/tx_1.png'),
            },{
                con:'感谢帮助我们实现家政教学品类内容服务商的全部想法。全面提升我们为用户服务的效率和质量，树立品牌感。',
                name:"王某",
                imgUrl:require('@/assets/images/platform/tx_2.png'),
            },{
                con:'军少烘培-旨在为从业者打造一个汇集国内外名师的学习社区，传播烘焙技术，慧职技为我们解决了线上教学的技术问题，为粉丝营造了一个沉浸式的学习氛围。',
                name:"蔡某",
                imgUrl:require('@/assets/images/platform/tx_3.png'),
            },{
                con:'慧职技平台最大的适配职业技能培训补贴业务，帮助我们传统培训机构成功转型互联网+职业技能培训的方向，提供了新的生命力！',
                name:"周某",
                imgUrl:require('@/assets/images/platform/tx_4.png'),
            },{
                con:'感谢帮助我们实现家政教学品类内容服务商的全部想法。全面提升我们为用户服务的效率和质量，树立品牌感。',
                name:"王某",
                imgUrl:require('@/assets/images/platform/tx_5.png'),
            },{
                con:'慧职技为提供了完整的职业培训解决方案，帮助我们将线下业务与线上业务融合，打通了内容到教学再到考评的完整闭环，全方位提高了我们的拓展能力。',
                name:"刘某",
                imgUrl:require('@/assets/images/platform/tx_6.png'),
            },{
                con:'军少烘培-旨在为从业者打造一个汇集国内外名师的学习社区，传播烘焙技术，慧职技为我们解决了线上教学的技术问题，为粉丝营造了一个沉浸式的学习氛围。',
                name:"蔡某",
                imgUrl:require('@/assets/images/platform/tx_7.png'),
            },{
                con:'慧职技平台最大的适配职业技能培训补贴业务，帮助我们传统培训机构成功转型互联网+职业技能培训的方向，提供了新的生命力！',
                name:"周某",
                imgUrl:require('@/assets/images/platform/tx_1.png'),
            }],
            swiperIndex:0,
            swiperPosition:0,
            box2Current:'1',
            box2TabList: [{
                id:1,
                tit:'线上线下深度融合',
                img:require('@/assets/images/platform/xianshang.png'),
                img_active:require('@/assets/images/platform/xianshang_1@2x.png'),
            },{
                id:2,
                tit:'高效学员管理',
                img:require('@/assets/images/platform/gaoxiao_2@2x.png'),
                img_active:require('@/assets/images/platform/gaoxiao_1@2x.png'),
            },{
                id:3,
                tit:'增长数据驱动',
                img:require('@/assets/images/platform/zengzhang_2@2x.png'),
                img_active:require('@/assets/images/platform/zengzhang_1@2x.png'),
            }]
        }
    },
    mounted() {
        
    },
    methods: {
        box2TabClick(item) {
            this.box2Current = item.id;
        },

        next() {
            if(this.swiperIndex<4){
                this.swiperIndex++;
                this.swiperPosition = -(this.swiperIndex * 298);
            }

        },
        prev() {
            if(this.swiperIndex>0){
                this.swiperIndex--;
                this.swiperPosition = -(this.swiperIndex * 298);
            }
        }
    }

}
</script>
<style lang="scss" scoped>
.platform {
    .banner {
        width: 100%;
        height: 330px;
        background: url('@/assets/images/bg/platform.png') no-repeat;
        background-size: 100% 100%;
    }
    .box1 {
        width: 100%;
        height: 596px;
        background: #fff;
        .img {
            width: 582px;
            height: 378px;
            background: url('@/assets/images/ptjs_1.png') no-repeat;
            background-size: 100% 100%;
            margin-right: 92px;
        }
        .text {
            h3 {
                font-size: 36px;
                color: #333333;
                line-height: 1;
                letter-spacing: 1.8px;
                margin-bottom: 50px;
            }
            p {
                font-size: 19px;
                color: #666666;
                line-height: 1.6;
                letter-spacing: 0.95px;
            }
        }
    }
    .box2 {
        width: 100%;
        height: 794px;
        background: #f7f9fb;
        padding-top: 70px;
        box-sizing: border-box;
        h1 {
            font-size: 36px;
            font-weight: 400;
            color: #333333;
            line-height: 1;
            letter-spacing: 1.8px;
        }
        .img {
            width: 840px;
            height: 480px;
            margin-left: -90px;
            margin-right: 90px;
            transition: all 0.8s;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .text {
            .tab {
                cursor: pointer;
                img {
                    vertical-align: middle;
                }
                span {
                    vertical-align: super;
                    font-size: 26px;
                    color: #555555;
                    line-height: 31px;
                    letter-spacing: 1.3px;
                }
            }
        }
    }
    .box3 {
        width: 100%;
        height: 973px;
        background: #fff;
        padding-top: 70px;
        box-sizing: border-box;
        h1 {
            font-size: 36px;
            font-weight: 400;
            color: #333333;
            line-height: 1;
            letter-spacing: 1.8px;
            margin-bottom: 75px;
        }
        .list {
            .item {
                width: 386px;
                height: 308px;
                background: #f9fafc;
                margin-bottom: 32px;
                padding: 52px 0px 69px 0px;
                box-sizing: border-box;
                img {
                    width: 106px;
                    height: 106px;
                }
                h3 {
                    font-size: 26px;
                    color: #333333;
                    line-height: 1;
                }
                p {
                    font-size: 18px;
                    color: #666666;
                    line-height: 1;
                    padding-top: 25px;
                }
            }
        }
    }
    .box4 {
        width: 100%;
        height: 754px;
        background: #f7f9fb;
        h1 {
            font-size: 36px;
            font-weight: 400;
            color: #333333;
            line-height: 1;
            padding: 75px 0px;
        }
        .my_swiper {
            width: 100%;
            height: 350px;
            box-sizing: border-box;
            overflow: hidden;
            .ul {
                width: 100%;
                height: 350px;
                z-index:1;
                display: flex;
                transition-property:transform;
                box-sizing:content-box;
                transition: all 0.8s;
                flex-wrap: nowrap;
                li {
                    width: 271px;
                    height: 314px;
                    border-radius: 10px;
                    box-shadow: 0px 0px 36px 0px rgba(205,206,208,0.20); 
                    margin-left: 28px;
                    flex-shrink:0;
                    transition: all 0.8s;
                    position: relative;
                    padding: 0 22px;
                    box-sizing: border-box;
                    background: #fff;
                    p {
                        width: 100%;
                        height: 135px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 5;
                        line-clamp: 5;
                        -webkit-box-orient: vertical;
                        padding-top: 74px;
                        font-size: 13px;
                        line-height: 28px;
                        margin-bottom: 56px;
                    }
                    .student {
                        bottom: 22px;
                        img {
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                        }
                        .name {
                            font-size: 14px;
                            font-weight: 400;
                            color: #333333;
                            padding-left: 15px;
                        }
                    }
                    &::after {
                        position: absolute;
                        top: 35px;
                        left: 70px;
                        content: '';
                        width: 21px;
                        height: 17px;
                        background: url('@/assets/images/douhao_1.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    &::before {
                        position: absolute;
                        bottom: 72px;
                        right: 62px;
                        content: '';
                        width: 21px;
                        height: 17px;
                        background: url('@/assets/images/douhao_2.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    &.active {
                        width: 303px;
                        height: 350px;
                        margin-left: 0px;
                        transition: all 0.8s;
                        padding: 0 32px;
                        p {
                            width: 100%;
                            height: 165px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 6;
                            line-clamp: 6;
                            -webkit-box-orient: vertical;
                            padding-top: 74px;
                            font-size: 15px;
                            line-height: 28px;
                            margin-bottom: 56px;
                        }
                        .student {
                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                            }
                            .name {
                                font-size: 14px;
                                font-weight: 400;
                                color: #333333;
                                padding-left: 15px;
                            }
                        }
                    }
                    
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .btn {
            padding-top: 30px;
            box-sizing: border-box;
            div {
                width: 56px;
                height: 46px;
                background: #ffffff;
                border-radius: 3px;
                box-shadow: 0px 0px 36px 0px rgba(205,206,208,0.30);
                line-height: 46px;
                text-align: center; 
                font-size: 30px;
                color: #999;
                cursor: pointer;
                &:last-of-type {
                    margin-left: 25px;
                }
            }
        }
    }
}
</style>