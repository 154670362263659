<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-06-10 08:44:23
 * @LastEditTime: 2022-10-20 17:09:29
 * @FilePath: \website\src\components\catalogue.vue
-->
<template>
    <div class="catalogue">
        <ul class="ul" v-for="(item,index) in list" :key="item.id">
            <div class="title">
                <!-- <i></i> -->
                <span>章节{{index+1}}: {{item.chapterName}}</span>
            </div>
            <li class="li dis-f flex-aic flex-jcsb" v-for="(item,index) in item.courseChapterVideoList" :key="item.id">
                <span>课时{{index+1}}: {{item.videoName}}</span>
                <span @click="videoPlay(item)" v-if="item.isTrial" class="btn pointer">免费试听</span>
            </li>
               
        </ul>

        <el-dialog
          title="课程试听"
          :visible.sync="videoShow"
          width="840px"
          >
          <video v-if="videoShow" ref="videoPlay" width="800px" autoplay :src="videoUrl" controls  controlsList="nodownload"></video>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name:"catalogue",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {
            videoShow:false,
            videoUrl:'',
        }
    },
    methods: {
        videoPlay(item) {
            this.videoUrl = item.videoPath;
            this.videoShow = true;
            // this.getVideoTime();
        },
        /**
           * 获取视频播放时长
        */
        getVideoTime () {
            if (this.$refs.videoPlay) {
                let videoPlayer = this.$refs.videoPlay;
                videoPlayer.addEventListener('timeupdate', function () {
                 console.log(`当前的时间点是${videoPlayer.currentTime},视频长度是${videoPlayer.duration}`);
                }, false)
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.catalogue {
    .ul {
        .title {
            width: 100%;
            height: 54px;
            background: #f5f8fa;
            border-radius: 3px;
            line-height: 54px;
            padding: 0 19px;
            
            i {
                display: inline-block;
                width: 16px;
                height: 16px;
                background: url('@/assets/images/catalogue_icon.png') no-repeat;
                background-size: 100% 100%;
                vertical-align: middle;
                margin-right: 10px;
            }
            span {
                vertical-align: middle;
                font-size: 18px;
                font-weight: 600;
            }
        }
        .li {
            height: 54px;
            font-size: 16px;
            color: #666666;
            padding-left: 45px;
            .btn {
                display: inline-block;
                width: 80px;
                height: 34px;
                border: 1px solid #1890ff;
                border-radius: 2px;
                line-height: 34px;
                text-align: center;
                font-size: 14px;
                color: #1890ff;
                &.active {
                    background: #1890ff;
                    color: #fff;
                }

            }
        }
    }
}
</style>