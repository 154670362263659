<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-16 16:54:29
 * @LastEditTime: 2022-10-25 11:39:29
 * @FilePath: \website\src\views\businessCooperation\index.vue
-->
<template>
  <div class="businessCooperation">
    <div class="banner" @click="toJump"></div>
    <div class="box1">
      <div class="w">
        <h1 class="text-c">合作方式</h1>
        <div class="list dis-f flex-jcsb">
          <div class="item text-c">
            <img src="@/assets/images/dailihezuo3.png" alt="" />
            <h3>企业/机构入驻</h3>
            <p>提供平台+内容+服务帮助企业</p>
            <p>/机构实现线上培训体系；</p>
          </div>
          <div class="item text-c">
            <img src="@/assets/images/dailihezuo2.png" alt="" />
            <h3>讲师入驻</h3>
            <p>广招名师，联合名师共同打造</p>
            <p>爆款产品，快速塑造名师品牌，</p>
            <p>帮助名师大限度发挥个人价值；</p>
          </div>

          <div class="item text-c">
            <img src="@/assets/images/dailihezuo1.png" alt="" />
            <h3>代理合作</h3>
            <p>寻找符合要求的机构、院校、</p>
            <p>社会组织等渠道资源，达成合</p>
            <p>作意向，满足企业培训需求；</p>
          </div>
          <div class="item text-c">
            <img src="@/assets/images/kechenghezuofang@2x.png" alt="" />
            <h3>课程合作方</h3>
            <p>寻找优质的课程内容提供商，实现</p>
            <p>价值交换，全方位满足用户的学习</p>
            <p>需求，共同搭建数字资源库；</p>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="w">
        <h1 class="text-c">合作流程</h1>
        <div class="bg dis-f flex-jcsb flex-aie">
          <p class="text-c flex1">1、提交合作申请</p>
          <p class="text-c flex1">2、确认合作内容与事项</p>
          <p class="text-c flex1">3、伙伴资质审核认证</p>
          <p class="text-c flex1" ref="fromData">4、签署协议达成合作</p>
        </div>
      </div>
    </div>
    <div class="ph"></div>
    <div class="box3">
      <div class="form_box w">
        <h2 class="text-c">申请成为慧职技平台的合作伙伴</h2>
        <p class="text text-c">
          留下您的信息，我们会有专业人员与您联系，一对一为您详细介绍我们的产品及业务，期待能够实现合作共赢！
        </p>
        <div class="form">
          <el-form
            :model="ruleForm"
            status-icon
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <div class="dis-f flex-jcsb">
              <el-form-item style="width: 398px" prop="name" label-width="0px">
                <el-input
                  type="text"
                  v-model="ruleForm.name"
                  placeholder="请输入公司/个人名称"
                ></el-input>
              </el-form-item>
              <el-form-item
                style="width: 398px"
                prop="telPhone"
                label-width="0px"
              >
                <el-input
                  type="text"
                  v-model.number="ruleForm.telPhone"
                  placeholder="请输入联系电话"
                ></el-input>
              </el-form-item>
            </div>
            <div class="dis-f flex-jcsb">
              <el-form-item style="width: 398px" prop="email" label-width="0px">
                <el-input
                  type="text"
                  v-model="ruleForm.email"
                  placeholder="请输入邮箱地址"
                ></el-input>
              </el-form-item>
              <el-form-item
                style="width: 398px"
                prop="belongAreaID"
                label-width="0px"
              >
                <el-cascader
                  ref="cityCascader"
                  style="width: 398px"
                  v-model="ruleForm.belongAreaID"
                  :options="cityData"
                  @change="handleChange"
                  placeholder="请选择地区"
                ></el-cascader>
              </el-form-item>
            </div>
            <el-form-item
              prop="cooperationDescription"
              label-width="0px"
              style="margin-bottom: 44px"
            >
              <el-input
                type="textarea"
                :rows="5"
                placeholder="请输入合作意向"
                v-model="ruleForm.cooperationDescription"
              ></el-input>
            </el-form-item>
            <el-form-item label-width="0px" class="text-c">
              <el-button type="primary" @click="submitForm('ruleForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/api/api";
import cityData from "@/utils/cityData.js";
export default {
  name: "businessCooperation",
  data() {
    return {
      cityData: cityData.address,
      ruleForm: {},
      belongArea: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.soure == "f_tab") {
        this.toJump();
      }
    });
  },
  methods: {
    toJump() {
      this.$refs.fromData.scrollIntoView(true);
    },
    submitForm() {
      if (!this.ruleForm.name) {
        this.$message.warning('请输入公司名称/个人名称');
        return false;
      }
      if (!this.ruleForm.telPhone) {
        this.$message.warning("请输入联系电话");
        return false;
      }
      var reg_phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg_phone.test(this.ruleForm.telPhone)) {
        this.$message.warning("请输入正确手机号");
        return false;
      }
      if (!this.ruleForm.email) {
        this.$message.warning("请输入邮箱");
        return false;
      }
      let reg_email =
        /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!reg_email.test(this.ruleForm.email)) {
        this.$message.warning("请输入正确邮箱");
        return false;
      }
      if (this.belongArea && this.belongArea.length > 0) {
        this.ruleForm.belongArea = this.belongArea.join("/");
      } else {
        this.$message.warning("请选择地区");
        return false;
      }

      API.busAdd(this.ruleForm)
        .then((res) => {
          if (res.code == 200) {
            // this.$message.success('申请成功');
            this.ruleForm = Object.assign({},this.$options.data().ruleForm);
            const h = this.$createElement;
            this.$msgbox({
                center:true,
                confirmButtonClass:"btnColor",
                message: h("div", null, [
                  h("p", { style: "color:#333;font-size:18px;padding:30px 0px 14px 0;box-sizing: border-box;" }, "提交成功，请耐心等待专业人员与您联系"),
                  h("p", { style: "font-size:14px;color:#666" }, "如有问题可致电联系我们：0731-82235978"),
                  h("p", { style: "font-size:14px;color:#666;margin-bottom:10px" }, "服务时间：工作日8:30-12:30  13:30-18:00"),
                ]),
                showCancelButton: false,
                confirmButtonText: "确定",
            })
          }
        })
        .catch((error) => {
          this.$message.error("请求异常");
        });
    },
    handleChange(value) {
      if (this.ruleForm.belongAreaID.length != 0) {
        this.belongArea =
          this.$refs["cityCascader"].getCheckedNodes()[0].pathLabels;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.businessCooperation {
  .banner {
    width: 1920px;
    height: 330px;
    background: url("@/assets/images/bg/businessCooperation.png") no-repeat;
    background-size: 100% 100%;
  }
  .box1 {
    width: 100%;
    height: 608px;
    background: #fff;
    h1 {
      font-size: 36px;
      font-weight: 400;
      color: #333333;
      line-height: 1;
      padding: 60px 0px;
      
    }
    .list {
      .item {
        width: 285px;
        height: 340px;
        background: #f9fafc;
        border-radius: 4px;
        padding-top: 34px;
        box-sizing: border-box;
        &:hover {
          transform: translate(0, -8px);
          transition: transform 0.5s;
        }
        img {
          width: 168px;
          height: 168px;
        }
        h3 {
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          line-height: 1;
        }
        p {
          font-size: 15px;
          color: #666666;
          line-height: 1;
          padding-top: 10px;
          box-sizing: border-box;
        }
      }
    }
  }
  .box2 {
    width: 100%;
    height: 508px;
    background: #f7f9fb;
    h1 {
      font-size: 36px;
      font-weight: 400;
      color: #333333;
      line-height: 1;
      padding: 60px 0px;
    }
    .bg {
      width: 1200px;
      height: 214px;
      border-radius: 10px;
      background: url("@/assets/images/hezuoliuc.png") no-repeat;
      background-size: 100% 100%;
      padding-bottom: 40px;
      box-sizing: border-box;
      p {
        font-size: 20px;
        color: #333333;
        line-height: 1;
      }
    }
  }
  .ph {
    width: 100%;
    height: 75px;
    background-color: #fff;
  }
  .box3 {
    width: 100%;
    height: 714px;
    background: #fff;
    background: url("@/assets/images/form_bg.png") no-repeat;
    background-size: 100% 100%;
    .form_box {
      height: 650px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: -2px 0px 23.4px 0.44px rgba(75, 138, 243, 0.1);
      padding: 0 182px;
      box-sizing: border-box;
      h2 {
        font-size: 36px;
        font-weight: 400;
        color: #333333;
        line-height: 1;
        padding: 92px 0px 30px 0px;
        box-sizing: border-box;
      }
      .text {
        font-size: 17px;
        font-weight: 400;
        color: #666666;
        line-height: 1;
        padding-bottom: 76px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
<style lang="scss">
.box3 {
  .form_box {
    .form {
      .el-textarea__inner {
        resize:none;
      }
      .el-input__inner,
      .el-textarea__inner {
        background-color: transparent;
        color: #333;
        border: 1px solid #d0d0d0;
        border-radius: 5px;
        &::placeholder {
          color: #999999;
          font-size: 16px;
        }
      }
      .el-input__inner {
        height: 48px;
        line-height: 48px;
      }
      .el-cascader .el-input .el-input__inner:focus,
      .el-cascader .el-input.is-focus .el-input__inner {
        border: 1px solid #d0d0d0 !important;
      }
      button {
        width: 310px;
        height: 50px;
        background: #1086ff;
        border-radius: 5px;
        color: #fff;
        margin: 0 auto;
      }
    }
  }
}
.btnColor {
    width: 80%;
    height: 34px;
}
</style>