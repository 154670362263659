<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:16:50
 * @LastEditTime: 2022-10-25 10:37:21
 * @FilePath: \website\src\App.vue
-->
<template>
  <div id="app">
    <top v-if="!loginShow" @loginOpen="loginOpen"></top>

    <navbar v-once></navbar>

    
    <div class="content">
      <router-view />
    </div>

    <floor v-once></floor>

    <login v-show="loginShow" @loginClose="loginClose"></login>
  </div>
</template>

<script>
import floor from '@/components/floor.vue';
import top from '@/components/top.vue';
import navbar from '@/components/navbar.vue';
import login from '@/components/login.vue';
import { mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
    }
  },
  components:{
    floor,top,navbar,login
  },
  computed: {
    ...mapState(['loginShow'])
  }, 
  created() {
    document.title = '慧职技'
  },
  // 使用watch 监听$router的变化,
  // watch: {
  //   '$route': function(to,from){
  //       document.body.scrollTop = 0
  //       document.documentElement.scrollTop = 0
  //   }
  // }
  methods: {
    ...mapMutations(['updateLogin']),
    loginOpen() {
      this.updateLogin(true)
    },
    loginClose() {
      this.updateLogin(false);
    }
  },
}
</script>

<style>
@import '@/assets/css/common.css';
#app {
  width: 100%;
  /* background: #f5f7f9; */
}
.content {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #f5f7f9;
  /* padding-bottom: 80px; */
}
</style>
