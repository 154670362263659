<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-16 16:45:40
 * @LastEditTime: 2022-10-25 09:33:33
 * @FilePath: \website\src\views\platform\product.vue
-->
<template>
  <div class="product">
    <div class="banner"></div>
    <div class="box1">
      <div class="w">
        <h1 class="text-c">多终端在线 学习无处不在</h1>
        <div class="list dis-f">
          <div
            @mouseenter="clickTab(index)"
            :class="index == tabType ? 'active' : ''"
            class="item text-c pointer"
            v-for="(item, index) in lists"
            :key="item.id"
          >
            <img class="icon" :src="item.icon" alt="" />
            <p>{{ item.title }}</p>
            <img
              v-if="index == tabType"
              :width="item.maxW"
              :height="item.maxH"
              class="maximg"
              :src="item.maxImg"
              alt=""
            />
            <img
              v-else
              class="minimg"
              :width="item.minW"
              :height="item.minH"
              :src="item.minImg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="w">
        <div class="list dis-f flex-jcsb flex-wrap">
          <div class="item text-c" v-for="item in box2Lists" :key="item.id">
            <div class="img">
              <img :src="item.icon" alt="" />
            </div>
            <h3>{{ item.title }}</h3>
            <p>{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="w">
        <h1 class="text-c">全方面管理 实时掌控数据信息</h1>
        <div class="lists">
          <ul class="tab_ul dis-f flex-jcsa">
            <li
              @click="swiperChange(index)"
              v-for="(item, index) in tabUl"
              :key="index"
              :class="swiper_active == index ? 'active' : ''"
            >
              {{ item }}
            </li>
          </ul>
          <div class="my_swiper">
            <ul
              class="ul"
              :style="`transform:translateX(${swiper_position}px)`"
            >
              <li><img src="@/assets/images/xueyuanguanl@2x.png" alt=""/></li>
              <li><img src="@/assets/images/xueqingchaxun@2x.png" alt=""/></li>
              <li><img src="@/assets/images/xiaoxituisong@2x@2x.png" alt=""/></li>
              <li><img src="@/assets/images/kechengshangchuan@2x.png" alt="" /></li>
              <li><img src="@/assets/images/kaoshiguanli@2x.png" alt="" /></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="box4">
      <div class="w">
        <h1 class="text-c">更多功能持续更新</h1>
        <div class="list dis-f flex-jcsb">
          <div class="item text-c">
            <img src="@/assets/images/nrfufei@2x.png" alt="" />
            <h3>内容付费</h3>
            <ul>
              <li>图文内容付费阅读</li>
              <li>音视频付费</li>
              <li>专栏订阅付费</li>
              <li>直播讲座付费</li>
            </ul>
          </div>
          <div class="item text-c">
            <img src="@/assets/images/zaixian_xianxia@2x.png" alt="" />
            <h3>在线&线下课程</h3>
            <ul>
              <li>在线学习</li>
              <li>直播体验课</li>
              <li>线下课程报名</li>
              <li>预约试听</li>
            </ul>
          </div>
          <div class="item text-c">
            <img src="@/assets/images/zhiboxitong@2x.png" alt="" />
            <h3>直播系统</h3>
            <ul>
              <li>微信互动直播</li>
              <li>手机现场直播</li>
              <li>电脑课件直播</li>
              <li>直播互动打赏</li>
            </ul>
          </div>
          <div class="item text-c">
            <img src="@/assets/images/yxiaozhaosheng@2x.png" alt="" />
            <h3>营销招生</h3>
            <ul>
              <li>分销</li>
              <li>拼团</li>
              <li>限时购</li>
              <li>邀请卡</li>
            </ul>
          </div>
          <div class="item text-c">
            <img src="@/assets/images/yunyinguanli@2x.png" alt="" />
            <h3>运营管理</h3>
            <ul>
              <li>客户管理</li>
              <li>社群互动</li>
              <li>会员体系</li>
              <li>数据分析</li>
            </ul>
          </div>
          <div class="item text-c">
            <img src="@/assets/images/gexinghua@2x.png" alt="" />
            <h3>个性化服务</h3>
            <ul>
              <li>个性化服务</li>
              <li>独立APP或SDK嵌入</li>
              <li>店铺自定义</li>
              <li>去版权私有化</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  data() {
    return {
      lists: [
        {
          id: 1,
          icon: require("@/assets/images/pc1.png"),
          title: "PC网页端",
          minImg: require("@/assets/images/pc_xiao@2x.png"),
          minW: "344px",
          minH: "207px;",
          maxImg: require("@/assets/images/pc_da@2x.png"),
          maxW: "250px",
          maxH: "150px",
        },
        {
          id: 2,
          icon: require("@/assets/images/pc2.png"),
          title: "移动APP",
          maxImg: require("@/assets/images/app_xiao.png"),
          minW: "130px",
          minH: "250px;",
          minImg: require("@/assets/images/app_da.png"),
          maxW: "150px",
          maxH: "290px",
        },
        {
          id: 3,
          icon: require("@/assets/images/pc3.png"),
          title: "公众号",
          minImg: require("@/assets/images/gongzhao_xiao.png"),
          minW: "130px",
          minH: "250px;",
          maxImg: require("@/assets/images/gongzhao_da.png"),
          maxW: "150px",
          maxH: "290px",
        },
        // ,{
        //     id:4,
        //     icon:require('@/assets/images/pc4.png'),
        //     title:"小程序",
        //     minImg:require('@/assets/images/pc_xiao@2x.png'),
        //     maxImg:require('@/assets/images/pc_da@2x.png'),
        // }
      ],
      tabType: 0,
      tabUl: ["学员管理", "学情查询", "消息推送", "课程上传", "考试管理"],
      swiper_active: 0,
      swiper_position: 0,
      box2Lists: [
        {
          id: 1,
          title: "课程学习",
          text: "丰富课程库资源",
          icon: require("@/assets/images/kechengxuexi.png"),
        },
        {
          id: 2,
          title: "培训直播",
          text: "在线直播高效教学",
          icon: require("@/assets/images/pxzhibo.png"),
        },
        {
          id: 3,
          title: "考试测评",
          text: "检测学习效果",
          icon: require("@/assets/images/kaoshiceping.png"),
        },
        {
          id: 4,
          title: "学习轨迹",
          text: "时刻掌握学习动态",
          icon: require("@/assets/images/xuexguiji@2x.png"),
        },
        {
          id: 5,
          title: "知识社区",
          text: "互动社区交流",
          icon: require("@/assets/images/zhishishequ@2x.png"),
        },
        {
          id: 6,
          title: "学习反馈",
          text: "意见反馈优化",
          icon: require("@/assets/images/xuexifankui@2x.png"),
        },
        {
          id: 7,
          title: "专家点评",
          text: "专业知识点输出",
          icon: require("@/assets/images/zhuanjiadianp@2x.png"),
        },
        {
          id: 8,
          title: "经验分享",
          text: "高效学习指南",
          icon: require("@/assets/images/jingyanfenx@2x.png"),
        },
      ],
    };
  },

  methods: {
    clickTab(index) {
      this.tabType = index;
    },
    swiperChange(index) {
      this.swiper_active = index;
      this.swiper_position = -(index * 1180);
    },
  },
};
</script>
<style lang="scss" scoped>
.product {
  .banner {
    width: 100%;
    height: 330px;
    background: url("@/assets/images/bg/product.png") no-repeat;
    background-size: 100% 100%;
  }
  .box1 {
    width: 100%;
    height: 885px;
    background: #ffffff;
    h1 {
      font-size: 36px;
      font-weight: 400;
      color: #333333;
      padding: 74px 0px;
      line-height: 1;
    }
    .list {
      .item {
        width: 360px;
        height: 560px;
        background: #ffffff;
        border: 1px solid #d9e2eb;
        padding-top: 75px;
        box-sizing: border-box;
        transition: all 0.8s;
        &:nth-of-type(1),
        &:nth-of-type(2) {
          border-right: 0px;
        }
        &.active {
          width: 481px;
          height: 560px;
          background: #ffffff;
          border: 1px solid #1890ff;
          border-radius: 10px 0px 0px 10px;
          box-shadow: 0px 0px 36px 0px rgba(49, 145, 255, 0.2);
          transition: all 0.8s;
        }
        .icon {
          width: 57px;
          height: 49px;
        }
        p {
          font-size: 24px;
          color: #666666;
          line-height: 1;
          padding: 32px 0px 56px 0px;
        }
        img {
          transition: all 0.8s;
        }
      }
    }
  }
  .box2 {
    width: 100%;
    height: 607px;
    background: #f7f9fb;
    .list {
      padding-top: 140px;
      box-sizing: border-box;
      .item {
        width: 290px;
        height: 118px;
        background: #ffffff;
        border: 1px solid #187aff;
        border-radius: 10px;
        margin-bottom: 86px;
        box-sizing: border-box;
        position: relative;
        &:hover {
          width: 290px;
          height: 118px;
          background: #1890ff;
          border: 4px solid #bfe0ff;
          box-sizing: border-box;
          h3,
          p {
            color: #fff;
          }
        }
        .img {
          width: 60px;
          height: 60px;
          position: absolute;
          left: 50%;
          margin-left: -30px;
          margin-top: -30px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        h3 {
          font-size: 22px;
          color: #333333;
          line-height: 1;
          padding: 42px 0px 15px 0px;
          font-weight: normal;
        }
        p {
          font-size: 18px;
          color: #666666;
          line-height: 1;
        }
      }
    }
  }
  .box3 {
    width: 100%;
    height: 983px;
    background: #ffffff;
    h1 {
      font-size: 36px;
      font-weight: 400;
      color: #333333;
      padding: 74px 0px;
      line-height: 1;
    }
    .lists {
      padding: 0 60px;
      box-sizing: border-box;
      .tab_ul {
        margin-bottom: 36px;
        padding: 0 30px;
        box-sizing: border-box;
        li {
          width: 160px;
          height: 58px;
          background: #ffffff;
          border: 2px solid #4ca7fe;
          border-radius: 8px;
          text-align: center;
          line-height: 58px;
          font-size: 22px;
          color: #1890ff;
          cursor: pointer;
          box-sizing: border-box;
          &:hover,
          &.active {
            background: #1890ff;
            border: 2px solid #bfe0ff;
            color: #fff;
            position: relative;
            &::after {
              content: "";
              width: 0px;
              height: 0px;
              border: 15px solid #1890ff;
              position: absolute;
              left: 50%;
              transform: translateX(-15px);
              bottom: -28px;
              border-color: #1890ff transparent transparent transparent;
            }
          }
        }
      }
      .my_swiper {
        width: 100%;
        height: 560px;
        // border: 15px solid #c3d8ef;
        
        border-radius: 8px;
        overflow: hidden;
        .ul {
          width: 6000px;
          height: 560px;
          
          transition: all 0.8s;
          li {
            width: 1080px;
            height: 560px;
            float: left;
            margin-right: 100px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .box4 {
    width: 100%;
    height: 605px;
    background: #f7f9fb;
    h1 {
      font-size: 36px;
      font-weight: 400;
      color: #333333;
      padding: 74px 0px;
      line-height: 1;
      box-sizing: border-box;
    }
    .list {
      .item {
        width: 190px;
        height: 280px;
        background: #ffffff;
        border-radius: 8px;
        padding-top: 36px;
        box-sizing: border-box;
        &:first-of-type {
          ul {
            padding-left: 26px;
          }
        }
        &:nth-of-type(3),
        &:nth-of-type(2) {
          ul {
            padding-left: 39px;
          }
        }
        &:nth-of-type(4) {
          ul {
            padding-left: 60px;
          }
        }
        &:nth-of-type(5) {
          ul {
            padding-left: 52px;
          }
        }
        &:nth-of-type(6) {
          ul {
            padding-left: 16px;
          }
        }
        img {
          width: 58px;
          height: 58px;
        }
        h3 {
          font-size: 18px;
          color: #333333;
          line-height: 1;
          padding: 20px 0px 16px 0px;
          box-sizing: border-box;
        }
        ul {
          li {
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            color: #777777;
            line-height: 1;
            position: relative;
            padding: 0px 0px 14px 13px;
            box-sizing: border-box;
            &::before {
              content: "";
              width: 3px;
              height: 3px;
              background: #1890ff;
              position: absolute;
              left: 0px;
              top: 5px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
</style>