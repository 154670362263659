<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-12 13:36:37
 * @LastEditTime: 2022-10-20 15:46:08
 * @FilePath: \website\src\components\top.vue
-->
<template>
    <div class="top">
        <div class="w dis-f flex-jce flex-aic">
            <div class="download pointer" @click="mapShow = true">APP下载</div> 
            <div class="login">
                <span v-if="isLogin=='true'" class="name">
                    <el-dropdown  @command="logout">
                        <span class="el-dropdown-link theme_color">
                            {{studentName || studentInfo.realName}}    
                        </span>
                        <el-dropdown-menu slot="dropdown" >
                            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </span>
                <span v-else @click="loginOpen" class="btn pointer">登录</span>
            </div> 
            <div @click="goManage" class="manage pointer">后台管理</div> 
        </div>
        <!-- 下载APP -->
        <dowApp-code @dowAppCode="dowAppCode" v-show="mapShow"></dowApp-code>
    </div>
</template>
<script>
import API from '@/api/api';
import dowAppCode from '@/components/dowAppCode.vue';
import { mapState } from 'vuex';
export default {
    name:"top",
    props: {

    },
    components: {
        dowAppCode
    },
    
    data() {
        return {
            mapShow:false,
            isLogin:localStorage.getItem('isLogin'),
            studentInfo: JSON.parse(localStorage.getItem('studentInfo')) || {},
        }
    },
    
    computed:{
        ...mapState(['studentName'])
    },
    methods: {
        dowAppCode(val) {
            this.mapShow = val
        },
        loginOpen(){
            this.$emit('loginOpen',true)
        },
        // 退出登录
        logout(e) {
            console.log(e);
            if(e == 'logout') {
                API.logout().then(res => {
                    if(res && res.success) {
                        this.$message.success('退出成功');
                        this.isLogin = false;
                        localStorage.clear();
                        setTimeout(() => {
                            this.$router.push({
                                path:'/index'
                            })
                        },2000)
                    }
                })
            }
            // this.$confirm('是否确定退出?', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(() => {
            //     this.$message({
            //         type: 'success',
            //         message: '删除成功!'
            //     });
            // }).catch(() => {
            //     this.$message({
            //         type: 'info',
            //         message: '已取消删除'
            //     });          
            // });
        },
        goManage() {
            window.open("http://zyjn.haokao123.com/admin", '_blank');
        }
    }
}
</script>
<style lang="scss" scoped>
.top {
    width: 100%;
    height: 40px;
    background: #f4f4f4;
    font-size: 14px;
    .download {
        width: 60px;
        color: #666666;
        position: relative;
        &::before {
            content: '';
            width: 14px;
            height: 20px;
            background: url('@/assets/images/appxiaz.png') no-repeat ;
            background-size: 100% 100%;
            position: absolute;
            left: -20px;
        }
    }
    .login {
        margin-left: 58px;
        span {
            display: inline-block;
            &.name {
                color: #649cf0;
            }
            &.btn {
                width: 65px;
                height: 23px;
                background: #649cf0;
                border-radius: 12px;
                line-height: 23px;
                text-align: center;
                color: #fff;
                
            }
        }
    }
    .manage {
        width: 75px;
        color: #555555;
        letter-spacing: 1.4px;
        border-left: 1px solid #555555;
        text-align: right;
        margin-left: 18px;
    }
}
</style>