<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-16 09:26:35
 * @LastEditTime: 2022-10-21 16:59:32
 * @FilePath: \website\src\views\course\courseCenter.vue
-->
<template>
    <div class="courseCenter">
        <breadcrumb :list="crumbLists"></breadcrumb>
        <div class="screen">
            <div class="category w dis-f">
                <div class="title">类别：</div>
                <div class="category_item dis-f flex-wrap flex1">
                    <span @click="categoryChange(index,item)" class="pointer" :class="index==categoryIndex?'active':''" v-for="(item,index) in this.categoryLists" :key="item.id">{{item.name}}</span>
                </div>
            </div>
            <div class="workType w dis-f" v-show="categoryIndex>0 && workTypeLists.length>0">
                <div>工种：</div>
                <div class="workType_item dis-f flex-wrap flex1">
                    <span @click="workTypeChange(index,item)" class="pointer" :class="index==workTypeIndex?'active':''" v-for="(item,index) in workTypeLists" :key="item.id">{{item.value}}</span>
                </div>
            </div>
        </div>
        <div class="w">
            <div class="list dis-f flex-wrap">
                <div class="item" v-for="item of lists" :key="item.id" @click="goDetails(item)">
                    <img :src="item.imageUrl" alt="">
                    <div class="info">
                        <p class="course_content inp_hidden2">{{item.courseName}}</p>
                        <p class="num">共计{{item.courseTime}}课时</p>
                        <p class="course_category">
                            <span>{{item.industryName}}</span>
                            <span>{{item.typeWorkName}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="w page dis-f flex-jcc">
            <el-pagination v-if="lists.length>0"
              background
              prev-text="上一页"
              next-text="下一页"
              layout="total, prev, pager, next, jumper"
              :current-page="reqData.pageNo"
              :page-size="reqData.pageSize"
              :total="reqData.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import API from "@/api/api"
import breadcrumb from '@/components/breadcrumb.vue';
export default {
    name:"courseCenter",
    components: {
        breadcrumb
    },
    data() {
        return {
            crumbLists:[
                {path:"/index",name:"首页"},
                {path:"/courseCenter",name:"课程中心"}
            ],
            categoryIndex:0,
            categoryLists:[],
            workTypeIndex:0,
            workTypeLists:[],
            reqData:{
                TypeWorkCode:"",
                IndustryCode:"",
                pageNo:1,
                pageSize:16,
                total:0,
                status:1,
                
            },
            lists:[],
            
        }
    },
    async created() {
        await this.getCategory();
        await this.getLists();
    },
    methods:{
        handleSizeChange(val) {
            this.reqData.pageSize = val;
            this.getLists();
        },
        handleCurrentChange(val) {
            this.reqData.pageNo = val;
            this.getLists();
        },
        // 类别
        getCategory() {
            API.getCategory({Code:"hy_"}).then(res => {
                if(res.code == 200) {
                    this.categoryLists = res.data.rows;
                    this.categoryLists.unshift({id: '1',name: "全部",code: ""})
                }
            }).catch(() => {
                this.$message.error('请求异常')
            })
        },
        // 工种
        dropDown(code) {
            API.dropDown({Code:code}).then(res => {
                if(res.code == 200) {
                    this.workTypeLists = res.data;
                    this.workTypeLists.unshift({id: '1',value: "全部",code: ""})
                }
            }).catch(() => {
                this.$message.error('请求异常')
            })
        },
        // 列表
        getLists() {
            API.getCourse(this.reqData).then(res => {
                this.lists = res.data.rows;
                this.reqData.total = res.data.totalRows;
            }).catch(() => {
                this.$message.error('请求异常')
            })
        },
        categoryChange(index,item) {
            this.reqData = this.$options.data().reqData
            this.categoryIndex = index;
            if(item.code) this.dropDown(item.code);
            this.reqData.IndustryCode = item.code;
            this.getLists();
        },
        workTypeChange(index,item) {
            this.workTypeIndex = index;
            this.reqData.TypeWorkCode = item.code;
            this.getLists();
        },
        goDetails(item) {
            this.$router.push({
                path:"/courseDateils",
                query:{id:item.id}
            })
        }

    }
}
</script>
<style lang="scss" scoped>
.courseCenter {
    .screen {
        width: 100%;
        background: #ffffff;
        padding: 26px 0px;
        .workType,
        .category {
            .title {
                width: 50px;
                font-size: 16px;
            }
            .workType_item,
            .category_item {
                width: 100%;
                span {
                    display: inline-block;
                    // width: 68px;
                    padding: 0 10px;
                    height: 26px;
                    line-height: 26px;
                    text-align: center;
                    border-radius: 5px;
                    font-size: 14px;
                    margin-left: 18px;
                    margin-bottom: 20px;
                    &:first-of-type {
                        margin-left: 18px;
                    }
                    &.active {
                        background: #d6eeff;
                        color: #0095ff;
                    }

                }
            }
        }
    }
    .list {
        width: 100%;
        padding-top: 40px;
        .item {
            margin-bottom: 40px;
            width: 283px;
            // height: 260px;
            background: #ffffff;
            border-radius: 10px;
            margin-right: 22px;
            &:hover {
              transform: translate(0, -8px);
              transition: transform 0.5s;
              box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.08);
            }
            &:nth-of-type(4n) {
                margin-right: 0px;
            }
            &:last-of-type {
                margin-right: 0px;
            }
            img {
                width: 283px;
                height: 140px;
                display: block;
            }
            .info {
                padding: 15px 21px 27px 21px;
                .course_content {
                    height: 44px;
                    font-size: 18px;
                    color: #333333;
                    line-height: 22px;
                }
                .num {
                    font-size: 14px;
                    color: #666666;
                    line-height: 32px;
                }
            }
            .course_category {
                span {
                    display: inline-block;
                    height: 24px;
                    line-height: 24px;
                    background: #f2f5f7;
                    border-radius: 3px;
                    margin-right: 16px;
                    text-align: center;
                    font-size: 13px;
                    color: #5b6068;
                    padding: 0 10px;
                    box-sizing: border-box;
                }
            }
        }
    }
    .page {
        padding-top: 65px;
        padding-bottom: 86px;
    }
}
</style>