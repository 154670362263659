<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-07-05 15:09:01
 * @LastEditTime: 2022-08-19 10:57:09
 * @FilePath: \website\src\components\studyCenter\studyArchives.vue
-->
<template>
    <div class="studyArchives">
            <ul class="tab dis-f flex-aic ma-b20">
                <li @click="tabClick('0')" :class="tabIndex=='0'?'active':''" class="tabLi font18 text-c pointer">全部</li>
                <li @click="tabClick('1')" :class="tabIndex=='1'?'active':''" class="tabLi font18 text-c pointer">已完成</li>
                <li @click="tabClick('2')" :class="tabIndex=='2'?'active':''" class="tabLi font18 text-c pointer">未完成</li>
            </ul>
            <ul class="ul">
                <li class="li dis-f flex-jcsb pos-r" v-for="(item,index) in [1,1,1]" :key="index" @click="showDetails(item)">
                    <div style="flex:3">
                        <h3>建筑工程监理培训班（中级班）</h3>
                        <p class="font14 mechanism">所属机构：海天酱油教育培训中心</p>
                        <div class="info dis-f flex-jcsb">
                            <span>班级：建筑工程监理培训班</span>
                            <span>考试时间：2022-03-09至2022-03-09</span>
                        </div>
                        <div class="num">
                            <span class="ma-r20">距离结束<em class="red">89</em>天</span>
                            <span class="ma-r20">总学时：<em class="black">89</em></span>
                            <span class="ma-r20">考核学时：<em class="black">89</em></span>
                            <span class="ma-r20">上次学习时间：<em class="black">89</em></span>                                            
                        </div>
                    </div>
                    <div class="progress">
                        <el-progress color="#4A8BFF" :width="84" :stroke-width="10" type="circle" :percentage="25"></el-progress>
                        <p class="font14 text-c pa-t10">学习进度</p>
                        <div class="statusImg">
                            <img src="@/assets/images/yjieshu.png" alt="">
                        </div>
                    </div>
                    <div class="tag text-c pos-a">未完成</div>
                </li>
            </ul>

    </div>
</template>
<script>
export default {
    name:"studyArchives",
    props: {
        current: {
            type: String,
            default:() => {
                return 'studyArchives'
            }
        }
    },
    data() {
        return {
            tabIndex:'0',
        }
    },
    methods:{
        tabClick(status) {
            this.tabIndex = status
        },
        // 详情
        showDetails(item) {
            this.$emit('tabClick',{name:"学习档案",id:'archivesInfo'})
        }
    },
    destroyed() {
      console.log('likai');  
    },
}
</script>
<style lang="scss" scoped>
.studyArchives {
    .tab {
        width: 100%;
        height: 70px;
        background: #ffffff;
        border-radius: 2px;
        padding: 0px 30px;
        box-sizing: border-box;
        .tabLi {
            width: 120px;
            height: 70px;
            line-height: 85px;
            color: #333;
            &.active {
                color: #109cff;
                font-weight: 700;
                position: relative;
                &::after {
                    content: '';
                    width: 75px;
                    height: 4px;
                    background: #109cff;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
    .ul {
        width: 100%;
        background: #ffffff;
        border-radius: 2px;
        padding: 0px 30px;
        box-sizing: border-box;
        .li {
            padding: 30px 0px;
            box-sizing: border-box;
            border-bottom: 1px solid #e5e5e5;
            h3 {
                font-size: 18px;
                line-height: 1;
                margin-bottom: 16px;
            }
            .mechanism {
                color: #666666;
                line-height: 1;
                margin-bottom: 16px;
            }
            .info {
                padding-bottom: 28px;
                span {
                    flex: 1;
                    font-size: 14px;
                    color: #999999;
                }
            }
            .num {
                color: #666666;
                em {
                    font-style: normal;
                    &.red {
                        color: #FF5C3C;
                    }
                    &.black {
                        color: #333333;
                    }
                }
                
            }
            .progress {
                padding-top: 30px;
                box-sizing: border-box;
                position: relative;
                .statusImg {
                    position: absolute;
                    width: 133px;
                    height: 133px;
                    top: 4px;
                    left: -84px;
                    z-index: -1;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            
            .tag {
                width: 80px;
                height: 25px;
                line-height: 25px;
                background: #ff3232;
                border-radius: 13px 0px 0px 13px;
                font-size: 14px;
                color: #ffffff;
                right: -30px;
                top: 14px;
                // 未完成 #FF3232; 已完成#35B770; 未完成#999999
            }

        }
    }
}
</style>