/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:56:52
 * @LastEditTime: 2022-11-18 17:14:34
 * @FilePath: \website\src\api\api.js
 */
import * as API from "./";
export default {
  // banner
  bannerLists: (params) => {
    return API.GET(`/siteBannerSetting/list`, params);
  },
  // 首页课程
  homepageCourse: (params) => {
    return API.GET(`/homepageCourse/page`, params);
  },
  // 帮助中心
  helpLists: (params) => {
    return API.GET(`/siteHelpcenterSetting/list`, params);
  },
  // 帮助中心详情
  helpDetail: (params) => {
    return API.GET(`/siteHelpcenterSetting/detail`, params);
  },
  // 帮助点击数
  helpNum: (params) => {
    return API.POST(`/siteHelpcenterSetting/changeClick`, params);
  },
  // 栏目管理
  manageList: (params) => {
    return API.GET(`/siteColmnManage/list`, params);
  },
  // 底部配置
  floorDetail: (params) => {
    return API.GET(`/siteSetting/detail`, params);
  },
  // 合作表单
  busAdd: (params) => {
    return API.POST(`/siteCooperationApply/add`, params);
  },
  // 友情链接
  dropDown: (params) => {
    return API.GET(`/sysDictType/dropDown`, params);
  },
  // 工种
  getCategory: (params) => {
    return API.GET(`/sysDictType/page`, params);
  },
  // 课程中心
  getCourse: (params) => {
    return API.GET(`/courseCenter/page`, params);
  },
  // 课程详情
  getCourseDetails: (params) => {
    return API.GET(`/courseCenter/courseDetail`, params);
  },
  // 获取验证码
  getCode: (params) => {
    return API.POST(`/sms/sendSmsInfo`, params);
  },
  // 登录
  login: (params) => {
    return API.POST(`/student/loginOrRegister`, params);
  },
  // 退出登录
  logout: (params) => {
    return API.GET(`/student/logout`, params);
  },
  // 用户信息
  accountData: (params) => {
    return API.GET(`/student/accountData`, params);
  },
  //班级列表
  classList: (params) => {
    return API.GET(`/officialSite/learningHub/classList`, params);
  },
  //课程列表
  courseList: (params) => {
    return API.GET(`/officialSite/learningHub/courseList`, params);
  },
  // 历史学习记录
  courseHistory: (params) => {
    return API.GET(`/officialSite/learningHub/courseHistory`, params);
  },
  // 我的班级列表
  classLists: (params) => {
    return API.GET(`/officialSite/learningHub/classList`, params);
  },
  // 我的班级详情
  classInfo: (params) => {
    return API.GET(`/officialSite/learningHub/classInfo`, params);
  },
  // 课程
  courseList: (params) => {
    return API.GET(`/officialSite/learningHub/courseList`, params);
  },
  // 章节
  chapterList: (params) => {
    return API.GET(`/officialSite/learningHub/chapterList`, params);
  },
  // 学习记录
  courseHistory: (params) => {
    return API.GET(`/officialSite/learningHub/courseHistory`, params);
  },
  // 章节
  chapterHistory: (params) => {
    return API.GET(`/officialSite/learningHub/chapterHistory`, params);
  },
  // 学习档案签到记录
  getArchivesSign: (params) => {
    return API.GET(`/student/checkin`, params);
  },
  // 我的考试
  examList: (params) => {
    return API.GET(`/officialSite/learningHub/examList`, params);
  },
};



