<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-07-05 10:33:35
 * @LastEditTime: 2022-08-17 15:38:07
 * @FilePath: \website\src\views\studyCenter\index.vue
-->
<template>
    <div class="studyCenter">
        <div class="w">
            <breadcrumb :list="crumbLists"></breadcrumb>
            <div class="dis-f">
                <div class="tab">
                    <studyCenterTab :current="current" @tabClick="tabClick"></studyCenterTab>
                </div>
                <div class="contentBox">
                    <!-- 班级学习 -->
                    <class-study @tabClick="tabClick" v-show="current == 'classStudy'"></class-study>
                    <!-- 我的考试 -->
                    <my-exam v-show="current == 'myExam'"></my-exam>
                    <!-- 学习档案 -->
                    <study-archives @tabClick="tabClick" :current="current" v-show="current == 'studyArchives'"></study-archives>
                    <!-- 账号设置 -->
                    <div class="accountSet" v-show="current == 'accountSet'">
                        账号设置
                    </div>

                    <!-- 查看课程 -->
                    <see-course ref="seeCourse" v-show="current == 'seeCourse'"></see-course>
                    <!-- 学习记录 -->
                    <study-records v-show="current == 'studyRecords'"></study-records>
                    <!-- 档案详情 -->
                    <archives-info v-show="current == 'archivesInfo'"></archives-info>
                    <!-- 课程视频 -->
                    <course-catalogue v-show="current == 'courseCatalogue'"></course-catalogue>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import API from "@/api/api";
import breadcrumb from '@/components/breadcrumb.vue';
import studyCenterTab from '@/components/studyCenter/studyCenterTab.vue';
import classStudy from '@/components/studyCenter/classStudy.vue';
import myExam from '@/components/studyCenter/myExam.vue';
import studyArchives from '@/components/studyCenter/studyArchives.vue';
import archivesInfo from '@/components/studyCenter/archivesInfo.vue';

import seeCourse from '@/components/studyCenter/seeCourse.vue';
import studyRecords from '@/components/studyCenter/studyRecords.vue';
import courseCatalogue from '@/components/studyCenter/courseCatalogue.vue';
export default {
    name:"studyCenter",
    components:{
        breadcrumb,studyCenterTab,classStudy,myExam,studyArchives,archivesInfo,seeCourse,studyRecords,courseCatalogue
    },
    data() {
        return {
            current:'classStudy',
            crumbLists:[
                {path:"/index",name:"首页"},
                {path:"/studyCenter",name:"学习中心"},
                {path:"",name:"班级学习"}
            ],
            classId:''
            
        }
    },
    methods: {
        tabClick(item) {
            console.log(item);
            this.current = item.id;
            this.crumbLists[2].name = item.name;
            this.classId = item.classId;
            if(item.id == 'seeCourse') {
                this.$refs.seeCourse.courseList(item.classId)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.studyCenter {
    .contentBox {
        flex: 1;
    }
    
}
</style>