<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-08-16 17:39:39
 * @LastEditTime: 2022-10-20 15:59:22
 * @FilePath: \website\src\components\login.vue
-->
<template>
    <div class="login">
      <div class="block text-c">
        <div class="close text-r ma-b10">
          <i class="pointer el-icon-close" @click="colse"></i>
        </div>
        <div class="login_from ma-b20">
            <h3>登录慧职技</h3>
            <el-form :model="loginFrom">
              <el-form-item>
                <el-input maxlength="11" v-model="loginFrom.PhoneNumber" placeholder="请输入手机号码"></el-input>
              </el-form-item>
              <el-form-item >
                <div class="dis-f felx-jcsb">
                    <el-input class="ma-r20" style="width:192px" v-model="loginFrom.VerificationCode"  placeholder="请输入验证码"></el-input>
                    <div class="codeBtn is-disabled" v-show="count > 0">{{ count }}s后重新获取</div>
                    <div v-show="count < 1" @click="getCode" class="codeBtn">获取验证码</div>
                </div>
              </el-form-item>
            </el-form>

        </div>
        
        <div :class="btnDis?'':'is-disabled'" class="btn" @click="login">登录</div>
      </div>
    </div>
</template>
<script>
import API from '@/api/api';
import api from '@/api/api';
import { mapMutations } from 'vuex';
export default {
    name:'login',
    data() {
        return {
            loginFrom:{
                PhoneNumber:'',
                VerificationCode:''
            },
            timer: null,
            count: 0,
        }
    },
    computed: {
        btnDis: function () {
          return this.loginFrom.PhoneNumber.length == 11 && this.loginFrom.VerificationCode.length == 6
        },
    },
    created() {

    },
    methods: {
        ...mapMutations(['updatestudentName']),
        colse() {
            this.$emit('loginClose',false);
            Object.assign(this.$data, this.$options.data());
            
        },
        getCode() {
            if(!this.loginFrom.PhoneNumber) {
                this.$message.warning('请输入手机号')
                return false
            }
            this.count = 60;
            API.getCode({
                PhoneNumbers:this.loginFrom.PhoneNumber
            }).then(res => {
                if(res && res.success) {
                    this.$nextTick(() => {
                      this.timer = setInterval(() => {
                        if (this.count > 0) {
                          this.count--;
                        } else {
                          clearInterval(this.timer);
                          this.timer = null;
                        }
                      }, 1000);
                    });
                }
            })
        },
        login() {
            if(!this.btnDis) return false; 
            if(!this.loginFrom.PhoneNumber) {
                this.$message.warning('请输入手机号')
                return false
            }
            if(!this.loginFrom.VerificationCode) {
                this.$message.warning('请输入验证码')
                return false
            }
            API.login(this.loginFrom).then(res => {
                if(res && res.success) {
                    localStorage.setItem('access-token',res.data)
                    localStorage.setItem('isLogin',true)
                    this.accountData();
                }
            })
        },
        accountData() {
            API.accountData().then(res => {
                if(res && res.success) {
                    this.updatestudentName(res.data.realName)
                    localStorage.setItem('studentInfo',JSON.stringify(res.data));
                    this.colse();
                    this.$router.push({
                        path:'/studyCenter'
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
  .block {
    width: 376px;
    height: 380px;
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 14px 30px;
    box-sizing: border-box;
    .close {
      font-size: 26px;
      color: #888888;
    }
    .login_from {
        h3 {
            font-size: 18px;
            color: #278dfe;
            margin-bottom: 36px;
        }
        .codeBtn{
            flex: 1;
            height: 40px;
            border-radius: 4px;
            color: #ffffff;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
            background: #278dfe;
            &.is-disabled {
                background: #eeeeee;
                color: #999;
            }
        }
    }
    .btn {
        width: 100%;
        height: 48px;
        background: #278dfe;
        border-radius: 4px;
        color: #ffffff;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        margin-top: 50px;
        &.is-disabled {
            background: #eeeeee;
            color: #999;
        }
    }
  }
}
</style>