<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-08-16 15:59:21
 * @LastEditTime: 2022-08-16 16:03:24
 * @FilePath: \website\src\components\dowAppCode.vue
-->
<template>
  <div class="dowAppCode">
    <div class="block text-c">
      <div class="close text-r ma-b10">
        <i class="pointer el-icon-circle-close" @click="colse"></i>
      </div>
      <div class="code ma-b20">
        <img src="@/assets/images/ewm0722.png" alt="" />
      </div>
      <div class="text font20">下载APP解锁更多功能！</div>

      <div class="btn">
        <span class="ios ma-b20"><i></i>iOS客户端</span>
        <span class="android"><i></i>Android客户端</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name:'',
    methods: {
        colse() {
            this.$emit('dowAppCode',false)
        }
    }
}
</script>

<style lang="scss" scoped>
.dowAppCode {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.4);
  .block {
    width: 450px;
    height: 561px;
    background: #ffffff;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 14px 20px;
    box-sizing: border-box;
    .close {
      font-size: 26px;
      color: #888888;
    }
    .code {
      img {
        width: 192px;
        height: 192px;
      }
    }
    .text {
      line-height: 1;
      margin-bottom: 50px;
    }
    .btn {
      span {
        display: inline-block;
        width: 275px;
        height: 60px;
        line-height: 60px;
        background: #109cff;
        border-radius: 30px;
        font-size: 22px;
        color: #fff;
        cursor: pointer;
        i {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
        &.ios {
          i {
            width: 24px;
            height: 29px;
            background: url("@/assets/images/video.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        &.android {
          i {
            width: 26px;
            height: 32px;
            background: url("@/assets/images/video.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>