<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-16 09:16:44
 * @LastEditTime: 2022-06-10 10:59:42
 * @FilePath: \website\src\components\breadcrumb.vue
-->
<template>
    <div class="breadcrumb">
        <div class="w dis-f flex-aic">
            <div class="title">您现在的位置：</div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <!-- <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item> -->
                <el-breadcrumb-item v-for="(item,index) in list" :key="index" :to="{ path: item.path }">{{item.name}}</el-breadcrumb-item>
                <!-- <el-breadcrumb-item>通用素质</el-breadcrumb-item> -->
            </el-breadcrumb>
        </div>
    </div>
</template>
<script>
export default {
    name:'breadcrumb',
    props: {
        list: {
            type: Array,
            default: () => {
                return [{path:"/index",name:"首页"}]
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
    width: 100%;
    height: 60px;
    .title {
        font-size: 16px;
        color: #999999;
        position: relative;
        padding-left: 19px;
        &::before {
            content: '';
            width: 4px;
            height: 17px;
            background: #109cff;
            border-radius: 2px;
            position: absolute;
            left: 0;
            top: 2px;
        }
    }

}
</style>
