<template>
    <div class="classStudy">
        <div class="dis-f flex-aic classNum">
            <img src="@/assets/images/banji.png" alt="">
            <span>您共有<span class="red">{{list.length}}</span>个班级</span>
        </div>
        <ul class="ul">
            <li class="li dis-f flex-jcsb" v-for="item in list" :key="item.classId">
                <div class="info">
                    <h3>{{item.className}}</h3>
                    <p class="type"><span>{{item.industryName}}</span> > <span>{{item.typeWorkName}}</span></p>
                    <p class="study-details">
                        <span>距离结束<em class="red">{{item.daysToEnd}}</em>天</span>
                        <span>结束日期：<em class="red">{{item.endDateOfClass}}</em></span>
                        <span>总学时：<em class="black">{{item.sumCourseTime}}</em></span>
                        <span>已学学时：<em class="black">{{item.learningCourseTime}}</em></span>
                        <span>上次学习时间：<em class="black">{{item.lastLearningTime}}</em></span>
                    </p>
                    <p class="btn">
                        <el-button @click="tabClick('course',item)" round type="primary">查看课程</el-button>
                        <el-button @click="tabClick('records',item)" plain round type="primary">学习记录</el-button>
                    </p>

                </div>
                <div class="progress">
                    <el-progress :width="84" :stroke-width="10" type="circle" :percentage="item.learningProgress"></el-progress>
                    <p class="font14 text-c pa-t10">学习进度</p>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import API from '@/api/api';
export default {
    name:'classStudy',
    data() {
        return {
            list:[],
        }
    },
    mounted() {
        this.classList();
    },
    methods: {
        tabClick(type,item) {
            if(type == 'course'){
                this.$emit('tabClick',{name:"班级学习",id:'seeCourse',classId:item.classId})
            }else {
                this.$emit('tabClick',{name:"班级学习",id:'studyRecords',classId:item.classId})
            }
        },
        // 班级列表
        classList() {
            API.classList().then(res => {
                if(res && res.success) {
                    this.list = res.data
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.classStudy {
    .classNum {
        width: 100%;
        height: 70px;
        background: #ffffff;
        border-radius: 2px;
        padding: 0 30px;
        box-sizing: border-box;
        margin-bottom: 18px;
        img {
            width: 24px;
            height: 24px;
            margin-right: 16px;
        }
        span {
            font-size: 16px;
            .red {
                color: #FF3232;
            }
        }
    }
    .ul{
            width: 100%;
            background: #ffffff;
            padding: 0px 30px;
            box-sizing: border-box;
            .li {
                padding: 30px 0px;
                box-sizing: border-box;
                border-bottom: 1px solid #eeeeee;
                .info {
                    h3 {
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                        line-height: 1;
                        margin-bottom: 12px;
                    }
                    .type {
                        font-size: 14px;
                        color: #666;
                        line-height: 1;
                        margin-bottom: 28px;
                    }
                    .study-details {
                        font-size: 14px;
                        color: #666666;
                        margin-bottom: 30px;
                        span {
                            margin-right: 20px;
                            .red {
                                font-style:normal;
                                color: #FF3232;
                            }
                            .black {
                                font-style:normal;
                                color: #333333;
                            }
                        }
                    }
                }
                .progress {

                }
            }
    }
}
</style>