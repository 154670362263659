/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:16:50
 * @LastEditTime: 2022-08-22 16:49:10
 * @FilePath: \website\src\store\index.js
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginShow:false,
    studentName:'',
    isLogin:false,
  },
  getters: {},
  mutations: {
    updateLogin(state,type) {
      state.loginShow = type
    },
    updatestudentName(state,type) {
      state.studentName = type
    },
    updatesisLogin(state,type) {
      state.isLogin = type
    }
  },
  actions: {},
  modules: {},
});
