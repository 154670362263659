<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-06-09 16:43:26
 * @LastEditTime: 2022-06-09 16:53:22
 * @FilePath: \website\src\components\dowCode.vue
-->
<template>
    <div class="dowCode">
        <div class="APP_code">
            <div class="code">
                <img :src="APPCode" alt="">
                <p>扫码下载APP</p>
            </div>
            <div class="tip text-c">
                <p>请扫描上方二维码下载</p>
                <p>职业技能培训平台APP进行学习</p>
            </div>
        </div>
        <div class="public_code">
            <div class="code">
                <img :src="publicCode" alt="">
                <p>扫码关注公众号</p>
            </div>
            <div class="tip text-c">
                <p>请扫描上方二维码关注职业技能培训</p>
                <p>平台公众号接收最新消息</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"dowCode",
    props: {
        
    },
    data() {
        return {
            APPCode:"",
            publicCode:""
        }
    },
    created() {
        this.APPCode = localStorage.getItem("APPCode");
        this.publicCode = localStorage.getItem("publicCode");
    },
}
</script>
<style lang="scss" scoped>
.dowCode {
    .APP_code {
        margin-bottom: 56px;
    }
    .public_code,
    .APP_code {
        .code {
            width: 240px;
            height: 266px;
            background: #ffffff;
            border: 1px solid #eeeeee;
            text-align: center;
            padding-top: 24px;
            box-sizing: border-box;
            margin-bottom: 18px;
            img {
                width: 192px;
                height: 192px;
                display: inline-block;
                margin-bottom: 12px;
            }
            p {
                font-size: 18px;
                color: #333333;
                line-height: 1;
            }
        }
        .tip {
            font-size: 14px;
            color: #666666;
            line-height: 20px;
        }
    }
}
</style>