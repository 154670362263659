<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-07-05 15:09:01
 * @LastEditTime: 2022-07-05 16:05:35
 * @FilePath: \website\src\components\studyCenter\myExam.vue
-->
<template>
    <div class="myExam">
        <ul class="tab dis-f flex-aic ma-b20">
            <li @click="tabClick('0')" :class="tabIndex=='0'?'active':''" class="tabLi font18 text-c pointer">全部</li>
            <li @click="tabClick('1')" :class="tabIndex=='1'?'active':''" class="tabLi font18 text-c pointer">已考试</li>
            <li @click="tabClick('2')" :class="tabIndex=='2'?'active':''" class="tabLi font18 text-c pointer">未考试</li>
        </ul>

        <ul class="ul pos-r" v-for="(item,index) in [1,1,1]" :key="index">
            <li class="li">
                <h3>建筑工程监理培训班（中级班）</h3>
                <div class="info dis-f flex-jcsb">
                    <span>班级：建筑工程监理培训班</span>
                    <span>考试时间：2022-03-09至2022-03-09</span>
                </div>
                <div class="exam dis-f flex-jcsb flex-aic">
                    <p class="font16">考试时长<span class="red">20分钟</span></p>
                    <div class="btn pointer text-c">开始考试</div>
                    <!-- <div class="achievement">成绩：100</div> -->
                </div>
                <div class="tag text-c pos-a">待考试</div>
            </li>
        </ul>
        

    </div>
</template>
<script>
export default {
    name:"myExam",
    data() {
        return {
            tabIndex:'0'
        }
    },
    methods:{
        tabClick(status) {
            this.tabIndex = status
        }
    }
}
</script>
<style lang="scss" scoped>
.myExam {
    .tab {
        width: 100%;
        height: 70px;
        background: #ffffff;
        border-radius: 2px;
        padding: 0px 30px;
        box-sizing: border-box;
        .tabLi {
            width: 120px;
            height: 70px;
            line-height: 85px;
            color: #333;
            &.active {
                color: #109cff;
                font-weight: 700;
                position: relative;
                &::after {
                    content: '';
                    width: 75px;
                    height: 4px;
                    background: #109cff;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
    .ul {
        width: 100%;
        background: #ffffff;
        border-radius: 2px;
        padding: 30px 30px 0px 30px;
        box-sizing: border-box;
        margin-bottom: 10px;
        .li {
            
            h3 {
                font-size: 18px;
                line-height: 1;
                margin-bottom: 16px;
            }
            .info {
                padding-bottom: 28px;
                border-bottom: 1px solid #e5e5e5;

                span {
                    flex: 1;
                    font-size: 14px;
                    color: #999999;
                }
            }
            .exam {
                height: 70px;
                .btn {
                    width: 100px;
                    height: 37px;
                    line-height: 37px;
                    background: #109cff;
                    color: #ffffff;
                    font-size: 16px;
                }
                .red {
                    color: #FF5C3C;
                }
                .achievement {
                    font-size: 20px;
                    font-weight: bold;
                    color: #ff3232;
                }
            }
            .tag {
                width: 80px;
                height: 25px;
                line-height: 25px;
                background: #ff3232;
                border-radius: 13px 0px 0px 13px;
                font-size: 14px;
                color: #ffffff;
                right: 0px;
                top: 18px;
                // 待考试#ff3232; 已考试#35B770;未开始#FF8040; 已过期#999999
            }

        }
    }
}
</style>