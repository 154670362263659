<template>
    <div class="archivesInfo">
        <div class="status pos-a">
            <img src="@/assets/images/yiwanchen.png" alt="">
        </div>
        <div class="student-info dis-f flex-jcsb flex-aic ma-b20">
            <div class="info">
                <span class="ma-r20">姓名：周杰伦</span>
                <span class="pa-l20">身份证号码：430304199912250836</span>
            </div>
            <div class="btn text-c">打印档案</div>
        </div>

        <div class="class-info ma-b20">
            <div class="title dis-f flex-jcsb flex-aic">
                <h3 class="font18">建筑工程监理培训班（中级班）</h3>
                <span class="font14">培训时间：2022-03-09至2022-03-09</span>
            </div>
            <p class="mechanism font14">所属机构：海天酱油教育培训中心</p>
            <p class="type font14">建筑与工程类 > 工程监理员</p>
            <div class="num font14">
                <span class="ma-r20">距离结束<em class="red">89</em>天</span>
                <span class="ma-r20 pa-l20">总学时：<em class="black">89</em></span>
                <span class="ma-r20 pa-l20">考核学时：<em class="black">89</em></span>
                <span class="ma-r20 pa-l20">已学学时：<em class="black">89</em></span>                                            
            </div>
        </div>
        <div class="class-info study-situation ma-b20">
            <div class="title dis-f flex-jcsb flex-aic">
                <h3 class="font18">学习情况</h3>
                <span class="font14">
                    <span class="ma-r20">总学时 900.00</span>
                    <span class="pa-l20">已学学时 450.00</span>
                </span>
            </div>
            <div class="pa-b20">
                <el-table :data="tableData" border style="width: 100%" :header-cell-style="{ background: '#F5F8FA',textAlign:'center' }">
                    <el-table-column width="360" prop="name" align="center" show-overflow-tooltip label="课程名称"></el-table-column>
                    <el-table-column prop="num" align="center" show-overflow-tooltip label="课程课时"></el-table-column>
                    <el-table-column prop="num" align="center" show-overflow-tooltip label="学习进度"></el-table-column>
                    <el-table-column prop="num" align="center" show-overflow-tooltip label="已学学时"></el-table-column>
                    <el-table-column prop="num" align="center" show-overflow-tooltip label="上次学时时长"></el-table-column>
                </el-table>
            </div>
        </div>
        <div class="class-info exam-situation">
            <div class="title dis-f flex-jcsb flex-aic">
                <h3 class="font18">考试情况</h3>
            </div>
            <div class="pa-b20">
                <el-table :data="tableData" border style="width: 100%" :header-cell-style="{ background: '#F5F8FA',textAlign:'center' }">
                    <el-table-column width="360" prop="name" align="center" show-overflow-tooltip label="考试名称"></el-table-column>
                    <el-table-column prop="num" align="center" show-overflow-tooltip label="考试时间"></el-table-column>
                    <el-table-column prop="num" align="center" show-overflow-tooltip label="考试时长"></el-table-column>
                    <el-table-column prop="num" align="center" show-overflow-tooltip label="考试成绩"></el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"archivesInfo",
    data() {
        return {
            tableData:[
                {
                    name:"这里是课程名称字数可能有点多的可能有点多的可的的",
                    num:'20.00'
                },{
                    name:"这里是课程名称字数可能有点多的可能有点多的可的的",
                    num:'20.00'
                },{
                    name:"这里是课程名称字数可能有点多的可能有点多的可的的",
                    num:'20.00'
                }
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.archivesInfo {
    position: relative;
    .status {
        width: 133px;
        height: 133px;
        top: 16px;
        right: 234px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .student-info {
        width: 100%;
        height: 70px;
        background: #fff;
        padding: 0 30px;
        box-sizing: border-box;
        .info {
            
        }
        .btn {
            width: 90px;
            height: 40px;
            line-height: 40px;
            background: #109cff;
            border-radius: 6px;
            color: #fff;
            font-size: 16px;
        }
    }
    .class-info {
        width: 100%;
        background: #fff;
        padding: 0 30px;
        box-sizing: border-box;
        .title {
            height: 64px;
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 16px;
            span {
                color: #999999;
            }
        }
        .mechanism {
            color: #666666;
            margin-bottom: 16px;
        }
        .type {
            color: #999999;
            margin-bottom: 32px;
        }
        .num {
            color: #666666;
            padding-bottom: 24px;
            em {
                font-style: normal;
                &.red {
                    color: #FF5C3C;
                }
                &.black {
                    color: #333333;
                }
            }
            
        }
    }
    .exam-situation,
    .study-situation{
        
        .title {
            h3 {
                padding-left: 12px;
                position: relative;
                &::before {
                    content: '';
                    width: 4px;
                    height: 18px;
                    background: #109cff;
                    position: absolute;
                    left: 0;
                    border-radius: 2px;
                }

            }
        }
    }
    
}
</style>