<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-07-08 11:15:17
 * @LastEditTime: 2022-08-17 15:44:47
 * @FilePath: \website\src\components\studyCenter\seeCourse.vue
-->
<template>
    <div class="seeCourse">
        <div class="dis-f flex-aic courseNum">
            <img src="@/assets/images/kecheng.png" alt="">
            <span>您共有<span class="red">{{list.length}}</span>个课程</span>
        </div>
        <ul class="ul">
            <li class="li dis-f flex-jcsb pos-r" v-for="item in list" :key="item.courseId" @click="showDetails(item)">
                <div class="img">
                    <img :src="item.coverUrl" alt="">
                </div>
                <div style="flex:3">
                    <h3 class="font18 pa-t10">{{item.courseName}}</h3>
                    <p class="font14 mechanism">{{item.industryName}} > {{item.typeWorkName}}</p>
                    <div class="info dis-f flex-jcsb">
                        <span style="flex:2">距离结束<em class="red">{{item.daysToEnd}}</em>天</span>
                        <span style="flex:7">结束日期：{{item.endDateOfClass}}</span>
                    </div>
                    <div class="num font14">
                        <span class="ma-r20">总学时：<em class="black">{{item.sumCourseTime}}</em></span>
                        <span class="ma-r20 pa-l20">已学学时：<em class="black">{{item.learningCourseTime}}</em></span>
                        <span class="ma-r20 pa-l20">上次学习时间：<em class="black">{{item.lastLearningTime}}</em></span>                                            
                    </div>
                </div>
                <div class="progress">
                    <el-progress color="#4A8BFF" :width="84" :stroke-width="10" type="circle" :percentage="item.learningProgress"></el-progress>
                    <p class="font14 text-c pa-t10">学习进度</p>
                    <div class="statusImg">
                        <img src="@/assets/images/yjieshu.png" alt="">
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import API from '@/api/api';
export default {
    name:'seeCourse',
    data() {
        return {
            list:[],
        }
    },
    mounted() {
        // this.courseList();
    },
    methods: {
        // 班级列表
        courseList(classId) {
            API.courseList({
                classId:classId
            }).then(res => {
                if(res && res.success) {
                    this.list = res.data
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.seeCourse {
    .courseNum {
        width: 100%;
        height: 70px;
        background: #ffffff;
        border-radius: 2px;
        padding: 0 30px;
        box-sizing: border-box;
        margin-bottom: 18px;
        img {
            width: 24px;
            height: 24px;
            margin-right: 16px;
        }
        span {
            font-size: 16px;
            .red {
                color: #FF3232;
            }
        }
    }
    .ul {
        width: 100%;
        background: #ffffff;
        border-radius: 2px;
        padding: 0px 30px;
        box-sizing: border-box;
        .li {
            padding: 20px 0px;
            box-sizing: border-box;
            border-bottom: 1px solid #e5e5e5;
            &:last-of-type {
                border: 0px;
            }
            .img {
                width: 225px;
                height: 150px;
                margin-right: 24px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            h3 {
                line-height: 1;
                margin-bottom: 12px;
            }
            .mechanism {
                color: #666666;
                line-height: 1;
                margin-bottom: 48px;
            }
            .info {
                padding-bottom: 14px;
                span {
                    flex: 1;
                    font-size: 14px;
                    color: #999999;
                    .red {
                        color: #FF5C3C;
                    }
                }
            }
            .num {
                color: #666666;
                em {
                    &.black {
                        color: #333333;
                    }
                }
                
            }
            .progress {
                padding-top: 30px;
                box-sizing: border-box;
                position: relative;
                .statusImg {
                    position: absolute;
                    width: 133px;
                    height: 133px;
                    top: 4px;
                    left: -84px;
                    z-index: -1;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>