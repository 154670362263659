<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-07-05 10:32:35
 * @LastEditTime: 2022-10-20 15:46:03
 * @FilePath: \website\src\components\studyCenter\studyCenterTab.vue
-->
<template>
    <div class="studyCenterTab">
        <div class="top">
            <div class="img ma-b20">
                <img class="portrait" src="@/assets/images/wode.png" alt="" srcset="">
                <img v-if="studentInfo.authStatus == 1" class="status" src="@/assets/images/zuanshi_1.png" alt="" srcset="">
                <img v-else class="status" src="@/assets/images/zuanshi_2.png" alt="" srcset="">
            </div>
            <p class="name">{{studentInfo.realName}}</p>
        </div>
        <div class="bottom">
            <ul class="ul">
                <li v-for="item in tabList" :class="item.currentList.includes(current)?'active':''" class="li" :key="item.id" @click="tabClick(item)">{{item.name}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name:"studyCenterTab",
    props: {
        current: {
            type:String,
            default:() => {
                return 'classStudy'
            }
        }
    },
    data() {
        return {
            studentInfo: JSON.parse(localStorage.getItem('studentInfo')),
            tabList:[
                {
                    name:"班级学习",
                    id:'classStudy',
                    currentList:['classStudy','seeCourse','studyRecords']
                },
                // {
                //     name:"我的考试",
                //     id:'myExam',
                //     currentList:['myExam']
                // },
                {
                    name:"学习档案",
                    id:'studyArchives',
                    currentList:['studyArchives','archivesInfo']
                },
                // {
                //     name:"账号设置",
                //     id:'accountSet',
                //     currentList:['accountSet']
                // },
            ],
        }
    },
    methods: {
        tabClick(item) {
            this.$emit('tabClick',item);
        }
    }
}
</script>
<style lang="scss" scoped>
.studyCenterTab {
    margin-right: 20px;
    .top {
        width: 240px;
        height: 206px;
        background: #ffffff;
        border-radius: 2px;
        position: relative;
        margin-bottom: 20px;
        .img {
            width: 86px;
            height: 86px;
            overflow: hidden;
            position: absolute;
            top: 34px;
            left: 50%;
            transform: translateX(-50%);
            .portrait {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
            .status {
                width: 39px;
                height: 33px;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        .name {
            font-size: 18px;
            font-weight: 700;
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .bottom {
        padding: 0 15px;
        background: #ffffff;
        .ul {
            .li {
                text-align: center;
                line-height: 1;
                padding: 30px 0;
                border-bottom: 1px solid #eeeeee;
                font-size: 18px;
                cursor: pointer;
                position: relative;
                &.active::after {
                    content: '';
                    width: 4px;
                    height: 36px;
                    background: #109cff;
                    position: absolute;
                    top: 50%;
                    left: -15px;
                    transform: translateY(-50%);

                }
                &:last-of-type {
                    border-bottom: 0px;
                }
            }
        }
    }
    
    
}

</style>