<template>
    <div class="navbar" :class="{ fixed: isFixed }">
        <div class="w dis-f flex-jcsb flex-aic">
            <div class="logo">慧职技</div>
            <div class="list">
                <el-menu router background-color="#fff" text-color="#333333" active-text-color="#0095FF" :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                  <template v-for="(item,index) in list">

                    <el-menu-item v-if="item.children.length<1" :index="item.url" :key="index">{{item.columnName}}</el-menu-item>

                    <el-submenu :index="String(index)" v-if="item.children.length>0" :key="index">
                        <template slot="title">{{item.columnName}}</template>

                        <el-menu-item v-for="items,insexs in item.children" :index="items.url" :key="insexs">{{items.columnName}}</el-menu-item>
                        <!-- <el-menu-item :index="item.url">直播课程</el-menu-item> -->
                    </el-submenu>
                  </template>
                  
                  <!-- <el-menu-item index="/index">首页</el-menu-item>
                  <el-submenu index="2">
                    <template slot="title">课程中心</template>
                    <el-menu-item index="/courseCenter">课程中心</el-menu-item>
                    <el-menu-item index="/courseLive">直播课程</el-menu-item>
                  </el-submenu>

                  <el-submenu index="3">
                    <template slot="title">平台介绍</template>
                    <el-menu-item index="/platform">平台介绍</el-menu-item>
                    <el-menu-item index="/product">产品介绍</el-menu-item>
                  </el-submenu>

                  <el-menu-item index="/studyCenter">学习中心</el-menu-item>
                  <el-menu-item index="/businessCooperation">商务合作</el-menu-item>
                  <el-menu-item index="/helpCenter">帮助中心</el-menu-item> -->
                </el-menu>

            </div>
            <div class="search">
                <el-input placeholder="请输入课程" v-model="searchKey" v-show="inpShow">
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
            </div>
        </div>
    </div>
</template>
<script>
import API from '@/api/api';
export default {
    name:"navbar",
    props: {

    },
    data() {
        return {
            searchKey:"",
            isFixed:false,
            tabColor:'#333',
            list:{}
        }
    },
    created() {
        this.manageList()
    },
    computed: {
        inpShow() {
          return this.$route.fullPath == '/courseCenter';
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll) // 监听滑动事件
    },

    methods: {
        handleSelect(key, keyPath) {
        //   console.log(key, keyPath);
        },
        handleScroll() {
          var scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop
          // console.log(scrollTop) // 滑动的长度
        //   var offsetTop = document.querySelector('.banner').offsetHeight

          // 吸顶效果
            if (scrollTop > 40) {
              this.isFixed = true;
              this.tabColor = '#fff';
            } else {
              this.isFixed = false;
              this.tabColor = '#333';
            }
        },
        manageList() {
            API.manageList().then(res => {
                if(res && res.success) {
                    this.list = this.formatToTree(res.data)
                }
            })
        },
        formatToTree(ary, pid) {
            return ary.filter(item =>
                pid === undefined ? item.belongModule === 0 : item.belongModule === pid
            ).map(item => {
                item.children = this.formatToTree(ary, item.id);
                return item;
            });
        }
    }
}

</script>
<style lang="scss" scoped>
.navbar {
    width: 100%;
    height: 88px;
    background-color: #fff;
    &.fixed {
        transition: all 1s;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        background-color: #fff;
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1)
    }
    .logo {
        width: 201px;
        height: 44px;
        font-size: 0px;
        background: url('@/assets/images/logo.png') no-repeat;
        background-size: 100% 100%;
    }
    .search {
        width: 206px;
        height: 34px;
        .el-input {
            input {
                width: 100% !important;
                height: 100% !important;
                background: #f4f4f4 !important;
                border-radius: 17px !important;
            }
        }
    }
}
</style>
<style lang="scss">
.list {
    .el-menu.el-menu--horizontal {
        border: 0px;
    }
    .el-menu--horizontal>.el-menu-item.is-active {
        border: 0px;
    }
    .el-submenu__title,
    .el-menu-item {
        font-size: 16px;
    }
}
.search {
    .el-input {
        input {
            width: 206px;
            height: 34px;
            background: #f4f4f4;
            border-radius: 17px;
        }
        .el-input__icon {
            line-height: 34px;
        }
    }
    .el-input__inner,
    .el-input.is-active .el-input__inner, .el-input__inner:focus {
        border: 0px !important;
    }
}
    li.el-menu-item,
    li.el-submenu .el-submenu__title {
        border: 0px !important;
    }
    li.el-submenu .el-submenu__title,
    li.el-menu-item:hover{
	  background: transparent !important;
	}
</style>