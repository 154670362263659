<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-16 15:03:27
 * @LastEditTime: 2022-10-21 10:03:59
 * @FilePath: \website\src\views\course\courseDateils.vue
-->
<template>
    <div class="courseDateils">
        <div class="w">
            <breadcrumb :list="crumbLists"></breadcrumb>
            <div class="info">
                <div class="infol">
                    <img class="img" :src="lists.imageUrl" alt="">
                    <!-- <img class='img_bf' src="@/assets/images/bofang.png" alt=""> -->
                </div>
                <div class="infor">
                    <h3 class="inp_hidden2">{{lists.courseName}}</h3>
                    <p class="inp_hidden2 color666"><span>简介：</span>{{lists.introduction}}<span></span></p>
                    <!-- <p><span> 讲师：</span><span>Windir</span></p> -->
                    <p class="color999"><span> 编号：</span><span>{{lists.courseCode}}</span></p>
                    <div class="category">
                        <p>{{lists.industryName}}</p>
                        <p>{{lists.typeWorkName}}</p>
                    </div>
                    <div class="btn" @click="codeShow = true">课程咨询</div>
                </div>
            </div>
            <div class="details">
                <div class="detailsL">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="介绍" name="introduce">
                            <div v-html="lists.introduce"></div>
                        </el-tab-pane>
                        <el-tab-pane label="目录" name="catalogue">
                          <catalogue :list="lists.courseChapterList"></catalogue>
                        </el-tab-pane>
                    </el-tabs>
                    
                </div>
                <div class="detailsR">
                    <dow-code></dow-code>
                </div>
            </div>
        </div>

        <el-dialog title="" :show-close="false" :visible.sync="codeShow" width="400px" center>
          <div>
            <div class="code"><img class="img" src="@/assets/images/ewm0722.png" alt="" srcset=""></div>
            <p class="text-c font16">扫码关注公众号</p>
            <p class="text-c font16">联系客服</p>
          </div>
        </el-dialog>
    </div>
</template>
<script>
import breadcrumb from '@/components/breadcrumb.vue';
import dowCode from "@/components/dowCode.vue";
import catalogue from "@/components/catalogue.vue";
import API from "@/api/api"
export default {
    name:'courseDateils',
    components: {
        breadcrumb,dowCode,catalogue
    },
    data() {
        return {
            activeName: 'catalogue',
            crumbLists:[
                {path:"/index",name:"首页"},
                {path:"/courseCenter",name:"课程中心"},
                {path:"/courseDateils",name:"课程详情"},
            ],
            id:'',
            lists:{},
            codeShow:false,
        }
    },
    created() {
        this.id = this.$route.query.id;
    },
    mounted() {
        this.getCourseDetails();
    },
    methods: {
        getCourseDetails() {
            API.getCourseDetails({id:this.id}).then(res => {
                if(res.code == 200) {
                    this.lists = res.data;
                }
            }).catch(() => {
                this.$message.error('请求异常')
            })
        },
        handleClick(tab, event) {
            console.log(tab, event);
            
        }
    }
}
</script>
<style lang="scss" scoped>
.courseDateils {
    padding-bottom: 100px;
    .info {
        height: 364px;
        background: #ffffff;
        border-radius: 5px;
        padding: 44px 46px;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 20px;
        .infol {
            width: 492px;
            height: 276px;
            // background: url('@/assets/images/course.png') no-repeat;
            // background-size: 100% 100%;
            position: relative; 
            .img {
                width: 100%;
                height: 100%;
            }
            .img_bf {
                width: 68px;
                height: 68px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                cursor: pointer;
            }
        }
        .infor {
            flex: 1;
            padding-left: 32px;
            box-sizing: border-box;
            position: relative;
            h3 {
                font-size: 26px;
                font-weight: 400;
                color: #333333;
                line-height: 1.4;
                margin-bottom: 20px;
            }
            p {
                font-size: 16px;
                text-align: left;
                // color: #666666;
                line-height: 1;
                &:first-of-type {
                    line-height:1.4;
                    margin-bottom: 5px;
                }
            }
            .btn {
                width: 150px;
                height: 50px;
                background: #f6612f;
                border-radius: 25px;
                font-size: 20px;
                color: #fff;
                line-height: 50px;
                text-align: center;
                cursor: pointer;
                position: absolute;
                bottom: 0px;
            }
            .category {
                padding-top: 27px;
                margin-bottom: 20px;
                p {
                    display: inline-block;
                    background: #f2f5f7;
                    height: 28px;
                    line-height: 28px;
                    text-align: center;
                    font-size: 15px;
                    color: #5b6068;
                    border-radius: 5px;
                    margin-right: 12px;
                    padding: 0 8px;
                }
            }
        }
    }
    .details {
        width: 100%;
        display: flex;
        
        .detailsL {
            flex: 1;
            background: #ffffff;
            padding: 0 40px;
        }
        .detailsR {
            width: 282px;
            height: 780px;
            background: #ffffff;
            border-radius: 5px;
            padding: 30px 20px 0px 20px;
            box-sizing: border-box;
            margin-left: 20px;
        }
    }
    .code {
        width: 300px;
        height: 300px;
        margin: 0 auto 20px auto;
        .img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>