<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-16 16:55:56
 * @LastEditTime: 2022-10-19 15:33:22
 * @FilePath: \website\src\views\helpCenter\index.vue
-->
<template>
    <div class="helpCenter">
        <div class="banner text-c">
            <h1>Hi, 请问有什么需要帮助的？</h1>
            <div class="help_search">
                <el-input placeholder="请输入内容" v-model="input" clearable>
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <el-button>搜索</el-button>
            </div>
        </div>
        <div class="box">
            <div class="w dis-f flex-jcsb">
                <div class="box_left">
                    <div class="list">
                        <div @click="$router.push({path:'/helpDetails?id='+item.id})" class="pointer item dis-f flex-jcsb" v-for="item in list" :key="item.id">
                            <span class="flex1 inp_hidden">{{item.articleTitle}}</span>
                            <span>{{item.createTime}}</span>
                        </div>
                    </div>
                    <!-- <div class="page dis-f flex-jcc">
                        <el-pagination
                          background
                          prev-text="上一页"
                          next-text="下一页"
                          layout="total, sizes, prev, pager, next, jumper"
                          :total="1000"
                          >
                        </el-pagination>
                    </div> -->
                </div>
                <div class="box_right">
                    <dow-code></dow-code>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from "@/api/api";
import dowCode from "@/components/dowCode.vue";
export default {
    name:"helpCenter",
    components: {
        dowCode
    },
    data() {
        return {
            input:'',
            list:[],

        }
    },
    mounted() {
        this.getLists();
    },
    methods: {
        getLists() {
            API.helpLists().then(res => {
                if(res.code == 200) {
                    this.list = res.data
                }
            }).catch(() => {
                this.$message.error('请求异常')
            })
        }
    }

}
</script>
<style lang="scss" scoped>
.helpCenter {
    padding-bottom: 104px;
    .banner {
        width: 100%;
        height: 330px;
        background: linear-gradient(0deg,#4481eb 0%, #17b5ff 100%);
        h1 {
            font-size: 44px;
            font-weight: 800;
            color: #ffffff;
            line-height: 1;
            text-shadow: -2px 0px 1.96px 0.04px #008ee8; 
            padding: 96px 0px 32px 0px;
            box-sizing: border-box;
        }
        .help_search {
            width: 660px;
            background: #f5f8fa;
            border-radius: 10px;
            margin: 0 auto;
            position: relative;
            .el-input__inner {
                width: 100%;
            }
            button {
                width: 62px;
                height: 33px;
                line-height: 33px;
                background: #109cff;
                border-radius: 6px;
                font-size: 14px;
                color: #f5f8fa;
                position: absolute;
                padding: 0px;
                right: 12px;
                top: 4px;
            }

            
        }
    }
    .box {
        padding-top: 30px;
        box-sizing: border-box;
        .box_left {
            width: 898px;
            background: #ffffff;
            .list {
                padding: 0 15px;
                margin-bottom: 36px;
                .item {
                    height: 70px;
                    line-height: 70px;
                    border-bottom: 1px dashed #e5e5e5;
                    padding: 0 5px;
                    span {
                        font-size: 16px;
                        &:first-of-type {
                            color: #333;
                            padding-right: 48px;
                            box-sizing: border-box;
                        }
                        &:last-of-type {
                            color: #666
                        }
                    }
                }
            }
        }
        .box_right {
            width: 282px;
            height: 780px;
            background: #ffffff;
            border-radius: 5px;
            padding: 30px 20px 0px 20px;
            box-sizing: border-box;
            
        }
    }
}
</style>