/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:16:50
 * @LastEditTime: 2022-10-26 11:32:45
 * @FilePath: \website\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import DonMessage from '@/utils/message'
import store from "@/store"

import index from "../views/index.vue";
import courseCenter from "../views/course/courseCenter.vue";
import courseDateils from "../views/course/courseDateils.vue";
import courseLive from "../views/course/courseLive.vue";
import platform from "../views/platform/index.vue";
import product from "../views/platform/product.vue";
import businessCooperation from "../views/businessCooperation/index.vue";
import helpCenter from "../views/helpCenter/index.vue";
import helpDetails from "../views/helpCenter/details.vue";
import studyCenter from "../views/studyCenter/index.vue";
import studyCenterOld from "../views/studyCenter/indexold.vue";



Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },{
    path: "/index",
    name: "index",
    component: index,
  },{
    path: "/courseCenter",
    name: "courseCenter",
    component: courseCenter,
  },{
    path: "/courseCenter",
    name: "courseCenter",
    component: courseCenter,
  },{
    path: "/courseDateils",
    name: "courseDateils",
    component: courseDateils,
  },{
    path: "/courseLive",
    name: "courseLive",
    component: courseLive,
  },{
    path: "/platform",
    name: "platform",
    component: platform,
  },{
    path: "/product",
    name: "product",
    component: product,
  },{
    path: "/businessCooperation",
    name: "businessCooperation",
    component: businessCooperation,
  },{
    path: "/helpCenter",
    name: "helpCenter",
    component: helpCenter,
  },{
    path: "/helpDetails",
    name: "helpDetails",
    component: helpDetails,
  },{
    path: "/studyCenter",
    name: "studyCenter",
    component: studyCenter,
  },{
    path: "/studyCenterOld",
    name: "studyCenterOld",
    component: studyCenterOld,
  },
  
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}


export default router;

router.beforeEach(async (to, from, next) => {
  let toPath = to.path
  if (toPath.indexOf('/studyCenter')!=-1) {
    if(localStorage.getItem('isLogin') == 'true') {
      next()
    }else {
      store.state.loginShow = true;
      return false;
    }
  } else {
    next()
  }
})