import { render, staticRenderFns } from "./floor.vue?vue&type=template&id=456a780f&scoped=true&"
import script from "./floor.vue?vue&type=script&lang=js&"
export * from "./floor.vue?vue&type=script&lang=js&"
import style0 from "./floor.vue?vue&type=style&index=0&id=456a780f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "456a780f",
  null
  
)

export default component.exports