<template>
    <div class="floor">
        <div class="w">
            <div class="top dis-f flex-jcsb flex-aic">
                <div class="left">
                    <ul class="ul dis-f flex-jcsb">
                        <li @click="tabjump('/index')" class="pointer">网站首页</li>
                        <li @click="tabjump('/helpCenter')" class="pointer">帮助中心</li>
                        <li @click="tabjump('/product')" class="pointer">产品介绍 </li>
                        <li @click="tabjump('/businessCooperation')" class="pointer">加入我们</li>
                    </ul>
                    <p>{{list.siteTitle}}</p>
                    <p>{{list.siteDescription}} </p>
                    <!-- <p>广大职称考试这里是一段简介的</p> -->
                </div>
                <div class="right dis-f flex-aie ">
                    <div>
                        <p>APP下载</p>
                        <img :src="list.appImage" alt="">
                    </div>
                    <div class="weixin">
                        <p>微信公众号</p>
                        <img :src="list.tencentOfficeImage" alt="">
                    </div>
                </div>
            </div>
            <div class="bottom dis-f flex-jcsb flex-aic">
                <div class="left">
                    <p>
                        <span>Copyright © 2016-2022 {{list.companyName}}</span>
                        <span class="pointer ma-l20">
                            <a target="_blank" href="http://beian.miit.gov.cn">湘ICP备15002531号-12</a>
                        </span>
                    </p>
                    <p>友情链接：
                        <span @click="jump(item)" v-for="item in dropDownList" :key="item.id" class="pointer ma-r20">{{item.value}} </span>
                        <!-- <span class="pointer ma-r20">慧职技继续教育 </span>
                        <span class="pointer ma-r20">经济师云课堂 </span> 
                        <span class="pointer ma-r20">经济师研修院 </span> -->
                    </p>
                    <p>企业地址：{{list.companyAddress}}</p>
                </div>
                <div class="right dis-f flex-aie">
                    <div class="call_icon"></div>
                    <div class="phone">
                        <p>{{list.companyTelphome}}</p>
                        <p>服务时间：工作日8:30-12:30  13:30-18:00</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from "@/api/api";
export default {
    name:"floor",
    props: {

    },
    data() {
        return {
            list:{},
            dropDownList:[],
        }
    },
    async mounted() {
        await this.dropDown();
        await this.getList();
    },
    methods: {
        
        getList() {
            API.floorDetail().then(res => {
                if(res.code == 200) {
                    this.list = res.data || {};
                    localStorage.setItem('APPCode',this.list.appImage)
                    localStorage.setItem('publicCode',this.list.tencentOfficeImage)
                }
            }).catch(() => {
                this.$message.error('请求异常')
            })
        },
        // 友情链接
        dropDown() {
            API.dropDown({Code:"youqinglianjie"}).then(res => {
                if(res.code == 200) {
                    this.dropDownList = res.data;
                }
            }).catch(() => {
                this.$message.error('请求异常')
            })
        },
        jump(item) {
            window.open(item.code)
        },
        tabjump(url) {
            this.$router.push({
                path:url,query:{
                    soure:'f_tab'
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.floor {
    width: 100%;
    height: 379px;
    background: #2e354d;
    .top {
        padding: 42px 0px;
        border-bottom: 1px solid #585d71;
        .left {
            ul {
                width: 421px;
                padding-bottom: 36px;
                li {
                    width: 105px;
                    font-size: 15px;
                    color: #ffffff;
                    text-align: left;
                    position: relative;
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3) {
                        &::after {
                            content: '';
                            width: 1px;
                            height: 14px;
                            background: #fff;
                            position: absolute;
                            top: 4px;
                            right: 24px;
                        }
                    }
                }
            }
            p {
                font-size: 14px;
                color: #b2b2bd;
                &:first-of-type {
                    padding-bottom: 22px;
                }
                &:nth-of-type(2) {
                    padding-bottom: 8px;
                }
            }
        }
        .right {
            .weixin {
                margin-left: 35px;
            }
            p {
                font-size: 14px;
                text-align: left;
                color: #b2b2bd;
                margin-bottom: 16px;
            }
            img {
                width: 86px;
                height: 86px;
            }
        }
    }
    .bottom {
        .left {
            font-size: 14px;
            color: #b2b2bd;
            line-height: 28px;
            letter-spacing: 0.28px;
            padding-top: 24px;
            a {
                text-decoration: none;
                color: #b2b2bd;
            }
        }
        .right {
            .call_icon {
                width: 50px;
                height: 50px;
                background: url('@/assets/images/dianhuahaoma.png') no-repeat;
                background-size: 100% 100%;
            }
            .phone {
                margin-left: 12px;
                p {
                    color: #b2b2bd;
                    line-height: 24px;
                    font-weight: 400;
                    &:first-of-type {
                        font-size: 26px;
                    }
                    &:last-of-type {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>
