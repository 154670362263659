<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-08-22 09:40:25
 * @LastEditTime: 2022-08-31 11:15:53
 * @FilePath: \website\src\components\studyCenter\selectClass.vue
-->
<template>
    <div class="selectClass">
        <el-dialog
            title="选择班级"
            :visible.sync="isShow"
            width="600px"
            center
            :before-close="close">
            <div class="ul">
                <div @click="switchClass(item)" :class="{active:item.classId == subClassId}" class="li pos-r ma-b10" v-for="item in classLists" :key="item.classId">
                    <h3>{{item.className}}</h3>
                    <p class="font14 ma-b10">所属机构：{{item.orgName}}</p>
                    <p class="dis-f flex-jcsb font14">
                        <span>{{item.industryName}} > {{item.typeWorkName}}</span>
                        <span>培训时间：{{item.startDateOfClass}}至{{item.endDateOfClass}}</span>
                    </p>
                    <div :class="item.classStatus" class="classStatus pos-a">{{item.classStatus}}</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer dis-ib text-r">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="confirm">确认选择</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name:"selectClass",
    props: {
        isShow: {
            type: Boolean,
            default: () => {
                return false
            }
        },
        classLists: {
            type:Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            subClassId:null,
        }
    },
    methods:{
        close() {
            this.$emit('classColse',false)
        },
        switchClass(item) {
            this.subClassId = item.classId;
        },
        confirm() {
            this.$emit('classConfrm',this.subClassId)
        }
    }
}
</script>
<style lang="scss" scoped>
.selectClass {
    .ul {
        .li {
            background: #f5f7f9;
            border-radius: 3px;
            padding: 26px;
            box-sizing: border-box;
            h3 {
                margin-bottom: 24px;
            }
            p {
                color: #666666;
            }
            .classStatus {
                right: 26px;
                top: 22px;
                width: 78px;
                height: 30px;
                border-radius: 15px;
                line-height: 30px;
                text-align: center;
                &.未开班 {
                    background: #666666;
                    color: #F4F4F4;
                }
                &.已开班 {
                    background: #4ac582;
                    color: #fff;
                }
                &.已结业 {
                    background: #eeeeee;
                    color: #666666;
                }
            }
            &.active {
                background: #89c3ff;
                h3,p {
                    color: #fff;
                }
            }
        }
    }
    .dialog-footer {
        width: 100%;
    }
}
</style>