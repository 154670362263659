<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-16 16:55:56
 * @LastEditTime: 2022-10-19 16:47:25
 * @FilePath: \website\src\views\helpCenter\details.vue
-->
<template>
    <div class="helpDetails">
        
        <!-- <div class="banner text-c">
            <h1>Hi, 请问有什么需要帮助的？</h1>
            <div class="help_search">
            </div>
        </div> -->
        <breadcrumb :list="crumbLists"></breadcrumb>
        <div class="box">
            <div class="w dis-f flex-jcsb">
                <div class="box_left">
                    <div class="details">
                        <h3 class="text-c ma-b20">{{info.articleTitle}}</h3>
                        <p class="text-c color666 font14 ma-b20">
                            <span class="ma-r20">发布时间：{{info.createTime}}</span>
                            <span>浏览数：{{info.clickNumber}}</span>
                        </p>
                        <p v-html="info.articleContent"></p>
                    </div>
                </div>
                <div class="box_right">
                    <dow-code></dow-code>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from "@/api/api";
import dowCode from "@/components/dowCode.vue";
import breadcrumb from '@/components/breadcrumb.vue';
export default {
    name:"helpDetails",
    components: {
        dowCode,breadcrumb
    },
    data() {
        return {
            crumbLists:[
                {path:"/index",name:"首页"},
                {path:"/helpCenter",name:"帮助中心"},
                {path:"",name:"帮助详情"},
            ],
            id:'',
            info:{},

        }
    },
    created() {
        this.id = this.$route.query.id;
    },
    mounted() {
        this.getLists();
        this.helpNum();
    },
    methods: {
        getLists() {
            API.helpDetail({
                id:this.id
            }).then(res => {
                if(res.code == 200) {
                    this.info = res.data;
                }
            })
        },
        helpNum() {
            API.helpNum({
                id:this.id
            }).then(res => {
                if(res.code == 200) {

                }
            })
        }
    }

}
</script>
<style lang="scss" scoped>
.helpDetails {
    padding-bottom: 104px;
    .banner {
        width: 100%;
        height: 330px;
        background: linear-gradient(0deg,#4481eb 0%, #17b5ff 100%);
        h1 {
            font-size: 44px;
            font-weight: 800;
            color: #ffffff;
            line-height: 1;
            text-shadow: -2px 0px 1.96px 0.04px #008ee8; 
            padding: 96px 0px 32px 0px;
            box-sizing: border-box;
        }
    }
    .box {
        // padding-top: 30px;
        box-sizing: border-box;
        .box_left {
            width: 898px;
            background: #ffffff;
            .details {
                padding: 30px;
                box-sizing: border-box;
            }
        }
        .box_right {
            width: 282px;
            height: 780px;
            background: #ffffff;
            border-radius: 5px;
            padding: 30px 20px 0px 20px;
            box-sizing: border-box;
            
        }
    }
}
</style>