<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-07-08 10:38:46
 * @LastEditTime: 2022-08-17 15:54:53
 * @FilePath: \website\src\components\studyCenter\studyRecords.vue
-->
<template>
    <div class="studyRecords">
        <div class="box">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="签到记录" name="first">签到记录</el-tab-pane>
              <el-tab-pane label="历史学习记录" name="record">
                <el-row class="pa-t20 ma-b20 record_row dis-f flex-jcsb ">
                    <el-col>
                        <el-col style="width:80px;height:40px;line-height:40px;">日期范围：</el-col>
                        <el-col class="ma-r10" style="width:350px">
                            <el-date-picker @change="searchTimeChange" unlink-panels style="height:40px;width:350px" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="searchTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                        </el-col>
                    </el-col>
                    <el-col>
                        <el-col class="ma-b10 text-r" style="width:500px">
                            <el-button type="primary" @click="search">查询</el-button>
                            <el-button @click="reset">重置</el-button>
                        </el-col>
                    </el-col>
                </el-row>
                <div>
                  <el-table
                    :data="tableData"
                    style="width: 100%;margin-bottom: 20px;"
                    row-key="id"
                    border
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column
                      prop="date"
                      label="学习时间"
                      width="180">
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      label="学习课程">
                    </el-table-column>
                    <el-table-column
                      prop="address"
                      label="学习时长(m)"
                      width="140">
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
export default {
    name:"studyRecords",
    data() {
        return {
            activeName: 'record',
            searchTime:'',
            tableData: [{
              id: 1,
              date: '2016-05-02',
              name: '章节1',
              address: '20'
            }, {
              id: 2,
              date: '2016-05-04',
              name: '章节1',
              address: '320'
            }, {
              id: 3,
              date: '2016-05-01',
              name: '章节1',
              address: '41',
              children: [{
                  id: 31,
                  date: '2016-05-01',
                  name: '章节1',
                  address: '52'
                }, {
                  id: 32,
                  date: '2016-05-01',
                  name: '章节1',
                  address: '41'
              }]
            }, {
              id: 4,
              date: '2016-05-03',
              name: '章节1',
              address: '21'
            }],
        }
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      searchTimeChange() {
        
      },
      search() {
        
      },
      reset() {

      },
      // 历史学习记录
      courseHistory() {

      },
    }
}
</script>
<style lang="scss" scoped>
.studyRecords {
    background-color: #fff;
    padding: 30px;
}
</style>