import { render, staticRenderFns } from "./courseCatalogue.vue?vue&type=template&id=d2c91104&scoped=true&"
import script from "./courseCatalogue.vue?vue&type=script&lang=js&"
export * from "./courseCatalogue.vue?vue&type=script&lang=js&"
import style0 from "./courseCatalogue.vue?vue&type=style&index=0&id=d2c91104&lang=scss&scoped=true&"
import style1 from "./courseCatalogue.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2c91104",
  null
  
)

export default component.exports