<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-07-08 14:04:33
 * @LastEditTime: 2022-08-17 14:40:38
 * @FilePath: \website\src\components\studyCenter\courseCatalogue.vue
-->
<template>
    <div class="courseCatalogue">
        <div class="dis-f flex-aic videoeNum">
            <img src="@/assets/images/video.png" alt="">
            <span>您共有<span class="red">2</span>个视频</span>
        </div>
        <div class="catalogue">
            <h3 class="font18">建筑工程监理课程</h3>
            <ul class="ul" v-for="(item,index) in [1,1,1,1]" :key="item.id">
                <div class="title font16">
                    章节{{index+1}}: 这里是章节的名字这里是章节的名字这里是章节的名字
                </div>
                <li class="li dis-f flex-aic flex-jcsb" v-for="(item,index) in [1,1,1]" :key="item.id">
                    <span style="flex:3" class="inp_hidden ma-r20">课时{{index+1}}：2022年二建《管理》专项考点预习课2022年二建《管理》专项考点预习课</span>
                    <span style="flex:2" class="ma-r20 ma-l20">
                        <!-- :format="progressFormat"  -->
                        <el-progress color="#35B770" :stroke-width="9" :percentage="40" ></el-progress>
                    </span>
                    <span class="btn pointer ma-l20" @click="mapShow = true">开始学习</span>
                </li>
            </ul>
        </div>
        <!-- 下载APP -->
        <dowApp-code @dowAppCode="dowAppCode" v-show="mapShow"></dowApp-code>
    </div>
</template>
<script>
import dowAppCode from '@/components/dowAppCode.vue';
export default {
    name:'courseCatalogue',
    data() {
        return {
            mapShow:false,
        }
    },
    components: {
        dowAppCode
    },
    methods: {
        progressFormat(percentage) {
            return '进度'+percentage+'%';
        },
        dowAppCode(val) {
            this.mapShow = val
        }
    }
}
</script>
<style lang="scss" scoped>
.courseCatalogue {
    .videoeNum {
        width: 100%;
        height: 70px;
        background: #ffffff;
        border-radius: 2px;
        padding: 0 30px;
        box-sizing: border-box;
        margin-bottom: 18px;
        img {
            width: 20px;
            height: 23px;
            margin-right: 16px;
        }
        span {
            font-size: 16px;
            .red {
                color: #FF3232;
            }
        }
    }
    .catalogue {
        padding: 20px 30px 0px 30px;
        box-sizing: border-box;
        background: #fff;
        h3 {
            height: 50px;
            line-height: 50px;
            background: #f5f8fa;
            position: relative;
            padding-left: 38px;
            &::before {
                content: '';
                width: 4px;
                height: 17px;
                background: #109cff;
                border-radius: 2px;
                position: absolute;
                left: 18px;
                top: 50%;
                transform: translateY(-50%);
                
            }
        }
        .ul {
            border-bottom: 1px solid #eeeeee;
            .title {
                height: 60px;
                border-radius: 3px;
                line-height: 60px;
                padding: 0 19px;
            }
            .li {
                height: 60px;
                font-size: 16px;
                color: #666666;
                padding-left: 45px;
                .btn {
                    display: inline-block;
                    width: 92px;
                    height: 34px;
                    border: 1px solid #1890ff;
                    border-radius: 2px;
                    line-height: 34px;
                    text-align: center;
                    font-size: 14px;
                    color: #1890ff;
                    &.active {
                        background: #1890ff;
                        color: #fff;
                    }
    
                }
            }
        }
    }
    
    
}
</style>
<style>
.el-progress-bar__outer {
    background-color: #E9EFEC !important;
}
</style>