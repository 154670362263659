<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-08-19 10:21:31
 * @LastEditTime: 2022-11-28 14:17:13
 * @FilePath: \website\src\views\studyCenter\index.vue
-->
<template>
    <div class="studyCenter">
        <div class="w">
            <breadcrumb :list="crumbLists"></breadcrumb>
            <div class="dis-f flex-jcsb flex-aic title">
                <div class="titleName">学习中心</div>
                <div v-if="this.classLists.length>0" class="switchClasss pointer"><span @click="switchClass">切换班级</span></div>
            </div>
            <div v-if="this.classLists.length>0">
                <div class="classInfo dis-f flex-jcsb pos-r">
                    <div style="flex:3">
                        <div class="dis-f flex-jcsb ma-b20">
                            <h3 class="inp_hidden2">{{classInfo.className}}</h3>
                            <span class="color999 fons14">培训时间：{{classInfo.startDateOfClass}}至{{classInfo.endDateOfClass}}</span>
                        </div>
                        <p class="font14 mechanism">所属机构：{{classInfo.orgName}}</p>
                        <div class="info dis-f flex-jcsb">
                            <span>{{classInfo.industryName}} > {{classInfo.typeWorkName}}</span>
                        </div>
                        <div class="num">
                            <span class="ma-r20">距离结束<em class="red">{{classInfo.daysToEnd}}</em>天</span>
                            <span class="ma-r20 ma-l20">总学时：<em class="black">{{classInfo.sumCourseTime}}</em></span>
                            <span class="ma-r20 ma-l20">已学课时：<em class="black">{{classInfo.learningCourseTime}}</em></span>                                            
                        </div>
                    </div>
                    <div class="progress">
                        <el-progress color="#4A8BFF" :width="84" :stroke-width="10" type="circle" :percentage="classInfo.learningProgress"></el-progress>
                        <p class="font14 text-c pa-t10">学习进度</p>
                        <div class="statusImg">
                            <img src="@/assets/images/yjieshu.png" alt="">
                        </div>
                    </div>
                    <div :class="classInfo.classStatus" class="tag text-c pos-a">{{classInfo.classStatus}}</div>
                </div>
                <div class="studyInfo">
                    <div class="isPa">
                        <h3 class="font18">学习情况</h3>
                        <div class="tableTitle dis-f flex-jcsb flex-aic">
                            <div class="flex3">课程名称</div>
                            <div class="flex1">课程课时</div>
                            <div class="flex1">已学课时</div>
                            <div class="flex2">学习进度</div>
                        </div>
                    </div>
                    <div class="table" v-if="courseList.length>0">
                        <div v-for="(item,index) in courseList" :key="item.courseId">
                            <div class="item dis-f flex-jcsb flex-aic ">
                                <div class="flex3" @click="getChapterList(item,index)">
                                    <p>
                                        <i v-show="item.chapterList.length>0" class="el-icon-arrow-up"></i>
                                        <i v-show="item.chapterList.length<1" class="el-icon-arrow-down"></i>
                                        <span class="ma-l10">{{item.courseName}}</span>
                                    </p>
                                </div>
                                <div class="flex1 text-c">{{item.sumCourseTime}}课时</div>
                                <div class="flex1 text-c">{{item.learningCourseTime}}课时</div>
                                <div class="flex2">
                                    <p><el-progress color="#35B770" :stroke-width="9" :percentage="item.learningProgress" ></el-progress></p>
                                </div>
                            </div>
                            <div > 
                                <!-- v-show="courseCurrent == item.courseId" -->
                                <ul class="ul isPa" v-for="(chapter,chapterindex) in item.chapterList" :key="chapterindex">
                                    <div class="chapter font16">{{chapter.chapterName}}</div>
                                    <li class="li dis-f flex-aic flex-jcsb" v-for="(video,videoindex) in chapter.videoList" :key="videoindex">
                                        <span style="flex:3" class="inp_hidden ma-r20">{{video.videoName}}</span>
                                        <span style="flex:2" class="ma-r20 ma-l20">
                                            <el-progress color="#35B770" :stroke-width="9" :percentage="video.progress" ></el-progress>
                                        </span>
                                        <span class="btn pointer ma-l20" @click="isDowAppCode = true">开始学习</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <el-empty v-else description="暂无数据"></el-empty>
                </div>
                <div class="studyRecord">
                    <div class="recordTitle dis-f flex-jcsb flex-aic">
                        <h3>学习记录</h3>
                        <p @click="signRecord" class="fons16 pointer">签到记录</p>
                    </div>
                    <div :style="isSign?'right:0px':'right:-600px'" class="signRecord" >
                        <el-calendar id="calendar" v-model="currentdate" v-show="isSign">
                            <template slot="dateCell" slot-scope="{date, data}">
                                <!-- <div v-if="date.toDateString().indexOf(currentdate.toDateString().split(' ')[1])!=-1"> -->
                                <div class="calendar-day">{{ data.day.split('-').slice(2).join('-') }}</div>
                                <div v-for="item,index in signData" :key="index">
                                    <div v-if="(item.months).indexOf(String(data.day.split('-').slice(1)[0])) != -1">
                                    <div v-if="(item.days).indexOf(data.day.split('-').slice(2).join('-')) != -1">
                                        <el-row class="calendarContent theme_color font12">
                                            <p class="sign">已签到</p>
                                            <p>{{item.time}}</p>
                                        </el-row>
                                    </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </template>
                        </el-calendar>
                    </div>
                    <el-row class="pa-t20 ma-b20 record_row ">
                            <el-col :span="2" style="height:40px;line-height:40px;">选择时间：</el-col>
                            <el-col :span="17" class="ma-r10" >
                                <!-- <el-date-picker @change="searchTimeChange" unlink-panels style="height:40px;width:350px" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="searchTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker> -->
                                <el-date-picker
                                  v-model="searchParams.startTime"
                                  type="date"
                                  format="yyyy-MM-dd"
                                  value-format="yyyy-MM-dd"
                                  placeholder="选择日期">
                                </el-date-picker>
                                <span class="ma-r10 ma-l10">至</span>
                                <el-date-picker
                                  v-model="searchParams.endTime"
                                  type="date"
                                  format="yyyy-MM-dd"
                                  value-format="yyyy-MM-dd"
                                  placeholder="选择日期">
                                </el-date-picker>
                            </el-col>
                            <el-col :span="5" class="ma-b10 text-r">
                                <el-button type="primary" @click="search">查询</el-button>
                                <el-button @click="reset">重置</el-button>
                            </el-col>
                    </el-row>
                    <div class="table">
                        <div class="tableTitle dis-f flex-jcsb flex-aic">
                            <div class="flex1">学习时间</div>
                            <div class="flex2">学习课程</div>
                            <div style="width:281px">学时时长(m)</div>
                        </div>
                        <div class="tableBody" v-if="courseHistory.length>0">
                            <div class="tableList">
                                <div class="tableItem dis-f flex-jcsb flex-aic" v-for="(item,index) in courseHistory" :key="index">
                                    <div class="flex1 text-c">{{item.date}}</div>
                                    <div class="flex3 bor-l ">
                                        <div class="dis-f flex-jcsb flex-aic chapter">
                                            <div class="flex2 pa-l20" @click="getChapterHistory(item,index)">
                                                <span class="ma-r10">{{item.courseName}}</span>
                                                <i v-show="item.chapterList.length>0" class="el-icon-arrow-up"></i>
                                                <i v-show="item.chapterList.length<1" class="el-icon-arrow-down"></i>
                                            </div>
                                            <div class="text-c bow-l">{{item.duration}}</div>
                                        </div>
                                        <ul>
                                            <li class="dis-f flex-jcsb flex-aic color666 minSection" v-for="items,indexs in item.chapterList" :key="indexs">
                                                <div class="flex1  pa-l20">{{items.chapterName}}</div>
                                                <div class="text-c bow-l ">{{items.duration}}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <el-empty v-else description="暂无数据"></el-empty>
                    </div>
                    <div class="w page dis-f flex-jcc pa-t20">
                        <el-pagination v-if="courseHistory.length>0"
                        background
                        prev-text="上一页"
                        next-text="下一页"
                        layout="total, prev, pager, next, jumper"
                        :current-page="searchParams.pageNo"
                        :page-size="searchParams.pageSize"
                        :total="searchParams.total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        >
                        </el-pagination>
                    </div>
                </div>
                <div class="exam">
                    <div class="isPa">
                        <h3 class="font18">我的考试</h3>
                        <div class="tableTitle dis-f flex-jcsb flex-aic">
                            <div class="flex3">考试名称</div>
                            <div class="flex2">考试编号</div>
                            <div class="flex2">考试时间</div>
                            <div class="flex1">状态</div>
                            <div class="flex1">理论成绩</div>
                            <div class="flex2">操作</div>
                        </div>
                    </div>
                    <div class="table" v-if="examList.length>0">
                        <div v-for="item,index in examList" :key="index">
                            <div class="item dis-f flex-jcsb flex-aic">
                                <div class="flex3 color666 inp_hidden">{{item.examName}}</div>
                                <div class="flex2 text-c">{{item.examNo}}</div>
                                <div class="flex2 text-c dis-f flex-aic">
                                    <li class="text-c flex1"> 
                                        <p>{{item.startTimeStr}}</p>
                                        <p> 至 </p>
                                        <p>{{item.endTimeStr}}</p>
                                    </li>
                                </div>
                                <div class="flex1 text-c">{{item.examStatusStr}}</div>
                                <div class="flex1 text-c">{{item.score}}</div>
                                <div class="flex2 text-c">
                                    <!--  @click="jump(item.url)" -->
                                    <span class="btn dis-ib" >
                                        <!-- 进入考试系统 -->
                                        <a :href="item.url" target="_blanke">进入考试系统</a>
                                    </span>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                    <el-empty v-else description="暂无数据"></el-empty>
                </div>
            </div>
            <div v-else>
                <el-empty description=" ">
                    <p class="color999">您的账号还未激活！</p>
                    <p class="color999">（请联系所属机构付费激活）</p>
                </el-empty>
            </div>
        </div>
        <dowApp-code @dowAppCode="dowAppCode" v-show="isDowAppCode"></dowApp-code>
        <select-class ref="selectClass" @classConfrm="classConfrm" @classColse="classColse" :classLists="classLists" :isShow="isShow"></select-class>
    </div>
</template>
<script>
import getDateTime from '@/utils/getDateTime';
import API from "@/api/api";
import breadcrumb from '@/components/breadcrumb.vue';
import dowAppCode from '@/components/dowAppCode.vue';
import selectClass from '@/components/studyCenter/selectClass.vue';
export default {
    name:'studyCenter',
    components:{
        breadcrumb,dowAppCode,selectClass
    },
    data() {
        return {
            crumbLists:[
                {path:"/index",name:"首页"},
                {path:"/studyCenter",name:"学习中心"},
            ],
            classLists:[],
            classInfo:[],
            courseList:[],
            isDowAppCode:false,
            courseCurrent:false,
            courseHistory:[],
            searchParams:{
                classId:'',
                pageNo:1,
                pageSize:10,
                startTime:'',
                endTime:'',
                total:0,
            },
            classId:null,
            isShow:false,
            month: 0, // 当前月份默认为0
            signData: [],  // {months:['08'] days: ['02','02'],time: '8:45',},
            currentdate:new Date(),
            isSign:false,
            examList:[],
        }
    },
    created() {
        this.getClassLists();
        this.getExamList();
    },
    mounted() {
        this.$nextTick(() => {
            // 点击前一个月
            let prevBtn = document.querySelector(
                ".el-calendar__button-group .el-button-group>button:nth-child(1)"
            );
            prevBtn.addEventListener("click", e => {
                this.month--
                this.getSignRecordLists('down')
            });
            //点击下一个月
            let nextBtn = document.querySelector(
                ".el-calendar__button-group .el-button-group>button:nth-child(3)"
            );
            nextBtn.addEventListener("click", () => {
                this.month++
                this.getSignRecordLists('down')
            });
            //点击今天
            let todayBtn = document.querySelector(
                ".el-calendar__button-group .el-button-group>button:nth-child(2)"
            );
            todayBtn.addEventListener("click", () => {
                this.month = 0
                this.getSignRecordLists('down')
            });
        });
    },
    methods: {
        // 选择班级
        switchClass() {
            this.$refs.selectClass.subClassId = this.classId;
            this.isShow = true;
        },
        classColse(val) {
            this.isShow = val;
        },
        classConfrm(val) {
            this.isShow = false;
            if(val != this.classId) {
                this.getList(val);
            }
        },
        // 班级列表
        getClassLists() {
            API.classLists().then(res => {
                if(res && res.success) {
                    this.classLists = res.data;
                    let classList = res.data;
                    classList.forEach(item => {
                        if(item.isDefault) {
                            this.getList(item.classId)
                        }
                    });
                }
            })
        },
        async getList(classId) {
            this.classId = classId;
            await this.getClassInfo(classId);
            await this.getCourseList(classId);
            await this.getCourseHistory();
        },
        // 班级信息
        getClassInfo(classId) {
            API.classInfo({
                classId:classId
            }).then(res => {
                if(res && res.success) {
                    this.classInfo = res.data
                }
            })
        },
        // 课程列表
        getCourseList(classId) {
            API.courseList({
                classId:classId
            }).then(res => {
                if(res && res.success) {
                    let courseList = res.data;
                    courseList.map(e => {
                        e.chapterList = []
                    })
                    this.courseList = courseList
                    
                }
            })
        },
        // 章节
        getChapterList(item,index) {
            this.courseCurrent = item.courseId;
            if(item.chapterList.length<1) {
                API.chapterList({
                    courseId:item.courseId
                }).then(res => {
                    if(res && res.success) {
                        this.courseList[index].chapterList = res.data;
                        this.$forceUpdate();  // 重新渲染v-for
                    }
                })

            }else {
                this.courseList[index].chapterList = [];
                this.$forceUpdate();  // 重新渲染v-for
            }
        },
        search() {
            this.searchParams.pageNo = 1;
            this.getCourseHistory();
        },
        reset() {
            this.searchParams = Object.assign(this.$data.searchParams, this.$options.data().searchParams);
            this.searchParams.classId = this.classId;
            this.getCourseHistory();
        },
        handleSizeChange(val) {
            this.searchParams.pageSize = val;
            this.getCourseHistory();
        },
        handleCurrentChange(val) {
            this.searchParams.pageNo = val;
            this.getCourseHistory();
        },
        // 学习记录
        getCourseHistory() {
            this.searchParams.classId = this.classId;
            API.courseHistory(this.searchParams).then(res => {
                if(res && res.success) {
                    let courseHistory = res.data.rows;
                    courseHistory.map(e => {
                        e.chapterList = []
                    })
                    this.courseHistory = courseHistory;
                    this.searchParams.total = res.data.totalRows;
                }
            })
        },
        // 章节历史记录
        getChapterHistory(item,index) {
            if(item.chapterList.length<1) {
                API.chapterHistory({
                    courseId:item.courseId,
                    date:item.date
                }).then(res => {
                    if(res && res.success) {
                        this.courseHistory[index].chapterList = res.data;
                        this.$forceUpdate();  // 重新渲染v-for
                    }
                })

            }else {
                this.courseHistory[index].chapterList = [];
                this.$forceUpdate();  
            }
        },
        // 开始学习
        dowAppCode(val) {
            this.isDowAppCode = val
        },
        // 签到记录
        signRecord() {
            this.isSign = !this.isSign;
            if(this.isSign) {
                this.getSignRecordLists();
            }
        },
        // 签到
        getSignRecordLists() {
            this.signData = [];
            let params = {
                month: this.month,
                classId: this.classId,
            }
            API.getArchivesSign(params).then(res => {
                if(res && res.success) {
                    let list = res.data;
                    list.forEach(e => {
                        e.days = [e.date.slice(e.date.length-2,e.date.length)];
                        e.months = [e.date.split('-').slice(1)[0]]
                    })
                    this.signData = list;
                }
            })
        },
        // 我的考试
        getExamList() {
            API.examList().then(res => {
                if(res && res.success) {
                    this.examList = res.data;
                }
            })
        },
        jump(url) {
            window.open(url, '_blank');
        }
    }
}
</script>
<style lang="scss" scoped>
.studyCenter {
    padding-bottom: 50px;
    .title {
        width: 100%;
        height: 70px;
        background: #ffffff;
        border-radius: 2px;
        padding: 0 30px;
        box-sizing: border-box;
        margin-bottom: 16px;
        .titleName {
            font-size: 20px;
            color: #109cff;
            &::before {
                content:'';
                display: inline-block;
                width: 22px;
                height: 24px;
                background: url('@/assets/images/xuexizx.png') no-repeat center;
                background-size: 100% 100%;
                vertical-align: bottom;
                margin-right: 18px;
            }
        }
        .switchClasss {
            span {
                display: inline-block;
                width: 100px;
                height: 38px;
                background: #109cff;
                line-height: 38px;
                text-align: center;
                color: #ffffff;
                font-size: 16px;
            }
        }
    }
    .classInfo {
        width: 100%;
        background: #ffffff;
        border-radius: 2px;
        padding: 23px 30px 16px 30px;
        box-sizing: border-box;
        margin-bottom: 16px;
        h3 {
            font-size: 18px;
            line-height: 1;
            width: 60%;
        }
        .mechanism {
            color: #666666;
            line-height: 1;
            margin-bottom: 16px;
        }
        .info {
            padding-bottom: 28px;
            span {
                flex: 1;
                font-size: 14px;
                color: #999999;
            }
        }
        .num {
            color: #666666;
            em {
                font-style: normal;
                &.red {
                    color: #FF5C3C;
                }
                &.black {
                    color: #333333;
                }
            }
        }
        .progress {
            padding-top: 30px;
            box-sizing: border-box;
            position: relative;
            .statusImg {
                position: absolute;
                width: 133px;
                height: 133px;
                top: 4px;
                left: -84px;
                z-index: -1;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .tag {
            width: 80px;
            height: 25px;
            line-height: 25px;
            border-radius: 13px 0px 0px 13px;
            font-size: 14px;
            right: 0px;
            top: 14px;
            &.未开班 {
                background: #666666;
                color: #F4F4F4;
            }
            &.已开班 {
                background: #4AC582;
                color: #ffffff;
            }
            &.已结业 {
                background: #eeeeee;
                color: #666666;
            }
        }
    }
    .studyInfo {
        width: 100%;
        background: #ffffff;
        border-radius: 2px;
        margin-bottom: 16px;
        padding-bottom: 30px;
        .isPa {
            padding: 0px 30px;
            box-sizing: border-box;
        }
        h3 {
            font-weight: 500;
            height: 62px;
            line-height: 62px;
            border-bottom: 1px dashed #e5e5e5;
            margin-bottom: 20px;
        }
        .tableTitle {
            width: 100%;
            height: 50px;
            background: #F5F8FA;
            padding: 5px 0px;
            box-sizing: border-box;
            div {
                text-align: center;
                border-right: 1px solid #D4DEE5;
                font-weight: 700;
                &:last-of-type {
                    border: 0px;
                }
            }
        }
        .table {
            .item {
                height: 79px;
                border-bottom: 1px dashed #e5e5e5;
                margin: 0 30px;
                div{
                    &:first-of-type {
                        p {
                            padding-left: 50px;
                            box-sizing: content-box;
                        }
                    }
                    &:last-of-type {
                        p {
                            padding: 0 25px;
                            box-sizing: content-box;
                        }
                    }
                }
            }
            .ul {
                background: #f9fafb;
                .chapter {
                    height: 60px;
                    border-radius: 3px;
                    line-height: 60px;
                    padding: 0 30px;
                    box-sizing: border-box;
                    border-top: 1px solid #eeeeee;
                }
                .li {
                    height: 60px;
                    font-size: 16px;
                    color: #666666;
                    padding: 0px 45px;
                    .btn {
                        display: inline-block;
                        width: 92px;
                        height: 34px;
                        border: 1px solid #1890ff;
                        border-radius: 2px;
                        line-height: 34px;
                        text-align: center;
                        font-size: 14px;
                        color: #1890ff;
                        &.active {
                            background: #1890ff;
                            color: #fff;
                        }
        
                    }
                }
            }
        }
    }
    .studyRecord {
        width: 100%;
        background: #ffffff;
        border-radius: 2px;
        margin-bottom: 16px;
        padding: 30px;
        box-sizing: border-box;
        position: relative;
        .recordTitle {
            height: 60px;
            border-bottom: 1px dashed #e5e5e5;
            h3 {
                font-size: 18px;
                font-weight: 500;
            }
            p {
                &::before {
                    content: '';
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: url('@/assets/images/qiandao.png') no-repeat center;
                    background-size: 100% 100%;
                    vertical-align: bottom;
                    margin-right: 10px;
                }
            }
        }
        .table {
            .tableTitle {
                width: 100%;
                height: 50px;
                background: #F5F8FA;
                padding: 5px 0px;
                box-sizing: border-box;
                div {
                    text-align: center;
                    border-right: 1px solid #D4DEE5;
                    font-weight: 700;
                    &:last-of-type {
                        border: 0px;
                    }
                }
            }
            .tableBody {
                min-height: 530px;
                .tableList {
                    border: 1px solid #EDF1F4;
                    border-top: 0px;
                    border-bottom: 0px;
                    .tableItem {
                        min-height: 50px;
                        line-height: 50px;
                        font-size: 16px;
                        border-bottom: 1px solid #EDF1F4;
                        .minSection,.chapter {
                            border-bottom: 1px solid #EDF1F4;
                            &:last-of-type {
                                border-bottom: 0px;
                            }
                        }
                        .bor-l {
                            border-left: 1px solid #EDF1F4;
                        }
                        .bow-l {
                            width: 280px;
                            border-left: 1px solid #EDF1F4;
                        }
                    }
                }
            }
        }
        .signRecord {
            width: 550px;
            font-size: 12px;
            position: absolute;
            right: 0px;
            top: 90px;
            background: #fff;
            z-index: 99;
            box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
            transition: all 1.2s;
            .calendarContent {
                .sign {
                    position: relative;
                    padding: 6px;
                    &::before {
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #0095FF;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    .exam {
        width: 100%;
        background: #ffffff;
        border-radius: 2px;
        margin-bottom: 16px;
        padding: 0px 30px 30px;
        box-sizing: border-box;
        h3 {
            font-weight: 500;
            height: 62px;
            line-height: 62px;
            border-bottom: 1px dashed #e5e5e5;
            margin-bottom: 20px;
        }
        .tableTitle {
            width: 100%;
            height: 50px;
            background: #F5F8FA;
            padding: 5px 0px;
            box-sizing: border-box;
            div {
                text-align: center;
                padding: 0 20px;
                box-sizing: border-box;
                font-weight: 700;
                &:last-of-type {
                    border: 0px;
                }
            }
        }
        .table {
            border: 1px solid #D4DEE5;
            border-top: 0px;
            border-bottom: 0px;
            .item {
                height: 98px;
                border-bottom: 1px solid #D4DEE5;
                div{
                    height: 98px;
                    line-height: 98px;
                    padding: 0 20px;
                    box-sizing: border-box;
                    border-right: 1px solid #D4DEE5;
                    &:last-of-type {
                        border-right: 0px;
                    }
                    p {
                        line-height: 1.4;
                    }
                    .btn {
                        width: 120px;
                        height: 36px;
                        background: #ffffff;
                        border: 1px solid #109cff;
                        border-radius: 3px;
                        text-align: center;
                        line-height: 36px;
                        color: #109cff;
                        font-size: 16px;
                        cursor: pointer;
                        a {
                            color: #109cff;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
.el-calendar-table{
    width: 100%;
    height: 100%;
    &:not(.is-range){
        //使不是本月的日期不可点击，不会跳转到其他月份
        td.next{
            pointer-events: none;
        }
        td.prev{
            pointer-events: none;
        }
        //td{
        //    pointer-events: none;
        //}
    }
}
</style>

