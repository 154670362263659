/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:47:54
 * @LastEditTime: 2022-08-24 09:13:07
 * @FilePath: \website\src\api\index.js
 */
import axios from "axios";
import DonMessage from '@/utils/message'


axios.defaults.withCredentials = true; //跨域带cookies
axios.defaults.timeout = 5000;  //设置超时时间

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? '/api' : '/api'

// axios.defaults.baseURL = 'http://192.168.51.198:5001/api'; //这是调用数据接口


//添加一个请求拦截器
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access-token"); //获取存储在本地的token
    // const refreshToken = localStorage.getItem('X-Access-Token'); //获取存储在本地的token
    config.data = JSON.stringify(config.data);
    config.headers = {
     'Content-Type':'application/json;charset=UTF-8' //设置跨域头部,虽然很多浏览器默认都是使用json传数据，但咱要考虑IE浏览器。
    };
    if (token) {
     config.headers['Authorization'] = 'Bearer ' + token; //携带权限参数
    }
    // if (refreshToken) {
    //   config.headers['X-Access-Token'] = refreshToken; //携带权限参数
    //  }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 添加一个响应拦截器
axios.interceptors.response.use(response => {
  if (response.headers['access-token'] && response.headers['access-token'] !== 'invalid_token') {
    localStorage.setItem('access-token',response.headers['access-token']);
  }
  
  // if (response.headers['x-access-token']) {
  //   localStorage.setItem('X-Access-Token',response.headers['x-access-token']);
  // }
  if (response.data && response.data.code) {
    if (parseInt(response.data.code) === 401) {
      //未登录
      DonMessage.warning('登录失效，请重新登录！');
      localStorage.setItem('isLogin',false);
      localStorage.removeItem('studentInfo');
      setTimeout(() => {
        window.location.href="/index";
      }, 2000);
    }else if(parseInt(response.data.code) === 403) {
      DonMessage.warning('您的账号在别处登录，请重新登录！');
      localStorage.setItem('isLogin',false);
      localStorage.removeItem('studentInfo');
      setTimeout(() => {
        window.location.href="/index";
      }, 2000);
    }else if(parseInt(response.data.code) === 500) {
      DonMessage.error(response.data.message);
    }
  }
  return response;
},

function (error) {
  // Do something with response error
  DonMessage.error('服务器异常')
  return Promise.reject(error);
}
); 




//通用方法
export const POST = (url, params) => {
  return axios.post(`${url}`, params)
    .then((res) => res.data);
};

export const GET = (url, params) => {
  return axios.get(`${url}`, {
      params: params,
    })
    .then((res) => res.data);
};

export const PUT = (url, params) => {
  return axios.put(`${url}`, params)
    .then((res) => res.data);
};


export const DELETE = (url, params) => {
  return axios.delete(`${url}`, {
    params: params
  }).then(res => res.data)
}

export const PATCH = (url, params) => {
  return axios.patch(`${url}`, params).then((res) => res.data);
};
