/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:16:50
 * @LastEditTime: 2022-10-20 11:23:59
 * @FilePath: \website\src\main.js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import myMessage from "@/utils/message"

import "element-ui/lib/theme-chalk/index.css";
import {MessageBox,Empty, Dropdown,DropdownMenu,DropdownItem,Calendar,Row,Col,TimePicker,Button, Select,DatePicker,Input,Menu,Submenu,MenuItem,Breadcrumb,BreadcrumbItem,Pagination,Cascader,Form,FormItem,Tabs,TabPane,Dialog,Progress,Table,TableColumn } from "element-ui";
Vue.use(Empty);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Calendar);
Vue.use(Row);
Vue.use(Col);
Vue.use(TimePicker);
Vue.use(Button);
Vue.use(Select);
Vue.use(DatePicker);
Vue.use(Input);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Pagination);
Vue.use(Cascader);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Dialog);
Vue.use(Progress);
Vue.use(Table);
Vue.use(TableColumn);

Vue.prototype.$message = myMessage;
Vue.prototype.$msgbox = MessageBox;
// import vueHashCalendar from 'vue-hash-calendar'
// import 'vue-hash-calendar/lib/vue-hash-calendar.css'
// Vue.use(vueHashCalendar)

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
