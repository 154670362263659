/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-12 08:51:49
 * @LastEditTime: 2022-10-20 11:11:52
 * @FilePath: \website\src\utils\message.js
 */
import { Message } from "element-ui";
const showMessage = Symbol('showMessage')
/** 
 * 
 *  重写ElementUI的Message
 *  single默认值true，因为项目需求，默认只弹出一个，可以根据实际需要设置
 */
let messageInstance = null;
class DonMessage {
  success (options, single = true) {
    this[showMessage]('success', options, single)
  }
  warning (options, single = true) {
    this[showMessage]('warning', options, single)
  }
  info (options, single = true) {
    this[showMessage]('info', options, single)
  }
  error (options, single = true) {
    this[showMessage]('error', options, single)
  }
  [showMessage] (type, options, single) {
    if (messageInstance && single) {
      messageInstance.close()//先把原来的关闭
    }
    messageInstance = Message[type]({
      message:options,
      offset:'350'
    })//再创建新的消息
  }
}
export default new DonMessage()